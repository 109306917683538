import { AccountBox, Call, CampaignOutlined, CurrencyRupee, DeliveryDining,
  Group, HomeRounded, HourglassEmpty, Info, Route, SupportAgent,
} from '@mui/icons-material';
import Content from '../components/Content';
import Home from '../home';
import Customers from '../screen/customers';
import AddUpdateUser from '../screen/AddUpdateUser';
import Staffs from '../screen/staffs';
import Schedules from '../screen/schedulse';
import Deliveries from '../screen/deliveries';
import DeliveryRouting from '../screen/deliveryRouting';
import ApplyRoute from '../screen/applyRoute';
import CustomerBilling from '../screen/customerBilling';
import Products from '../screen/products';
import AboutUs from '../screen/aboutUs';
import ContactUs from '../screen/contactUs';
import Profile from '../screen/profile';
import GenerateInvoice from '../screen/generateInvoice';

const getCustomersRoute = () => ({
  path: '/customers',
  element: <Content hasNavBar><Customers/></Content>,
  label: 'Customers',
  Icon: Group,
});

const getDeliveriesRoute = () => ({
  path: '/deliveries',
  element: <Content hasNavBar><Deliveries/></Content>,
  label: 'Deliveries',
  Icon: DeliveryDining,
});

const getStaffsRoute = () => ({
  path: '/staffs',
  element: <Content hasNavBar><Staffs/></Content>,
  label: 'Staffs',
  Icon: SupportAgent,
});

const getBillingRoute = () => ({
  path: '/billing',
  element: <Content hasNavBar>{'Billing'}</Content>,
  label: 'Billing',
  Icon: CurrencyRupee,
});

const getCustomerBillingRoute = () => ({
  path: '/invoice',
  element: <Content hasNavBar><CustomerBilling/></Content>,
  label: 'Billing',
  Icon: CurrencyRupee,
});

const getProductsRoute = () => ({
  path: '/products',
  element: <Content hasNavBar><Products/></Content>,
  label: 'Products',
  Icon: CampaignOutlined,
});

const getProfileRoute = () => ({
  path: '/profile',
  element: <Content hasNavBar><Profile /></Content>,
  label: 'Profile',
  Icon: AccountBox,
});

const getAboutUsRoute = () => ({
  path: '/aboutUs',
  element: <Content hasNavBar><AboutUs/></Content>,
  label: 'About Us',
  Icon: Info,
});

const getContactUsRoute = () => ({
  path: '/contactUs',
  element: <Content hasNavBar><ContactUs/></Content>,
  label: 'Contact Us',
  Icon: Call,
});

const getSchedules = () => ({
  path: '/schedules',
  element: <Content hasNavBar><Schedules /></Content>,
  label: 'Schedules',
  Icon: HourglassEmpty,
});

const getRouting = () => ({
  path: '/routing',
  element: <Content hasNavBar><DeliveryRouting /></Content>,
  label: 'Delivery Routing',
  Icon: Route,
});

const addUserRoute = () => ({
  path: '/user',
  element: <Content hasNavBar><AddUpdateUser/></Content>,
  label: 'Add/Update User',
});

const getApplyRoute = () => ({
  path: '/applyRoute',
  element: <Content hasNavBar><ApplyRoute /></Content>,
  label: 'Add Customer and Apply Route',
});

const getGenerateInvoice = () => ({
  path: '/generateInvoice',
  element: <Content hasNavBar><GenerateInvoice/></Content>,
  label: 'Generate Customer Invoice',
  Icon: CurrencyRupee,
});

const captureNavigationItems = ({path, label, Icon}) => ({path, label, Icon});

export const getAdduserNavigation = () =>
  captureNavigationItems(addUserRoute());

const getAuthorizedNavigations = (user) => {
  if (!user) {
    return [];
  }

  if (user.currentRole === 'Admin') {
    return [getCustomersRoute(), getDeliveriesRoute(), getBillingRoute(),
      getStaffsRoute(), getSchedules(), getRouting(), getGenerateInvoice()];
  } else if (user.currentRole === 'Manager') {
    return [getCustomersRoute(), getDeliveriesRoute(), getStaffsRoute(),
      getSchedules(), getRouting()];
  } else if (user.currentRole === 'Customer') {
    return [getCustomerBillingRoute(), getProductsRoute(), getProfileRoute(),
      getAboutUsRoute(), getContactUsRoute()];
  } else if (user.currentRole === 'Delivery Agent') {
    return [];
  } else {
    return [];
  }
};

const getHome = () => ({
  path: '/home',
  element: <Content hasNavBar><Home/></Content>,
  label: 'Home',
  Icon: HomeRounded,
});

export const getUserNavigations = (user=undefined) => (user && [
  getHome(),
  ...getAuthorizedNavigations(user),
].map(captureNavigationItems)) || [];

export const getAllRoutes = () => {
  return [getHome(), getCustomersRoute(), getDeliveriesRoute(),
    getBillingRoute(), getStaffsRoute(), addUserRoute(), getSchedules(),
    getRouting(), getApplyRoute(), getCustomerBillingRoute(),
    getProductsRoute(), getProfileRoute(), getAboutUsRoute(),
    getContactUsRoute(), getGenerateInvoice()]
      .map(({path, element}) => ({path, element}));
};
