import { TextField, Typography } from "@mui/material";
import CommonModal from "./CommonModal";
import { useContext, useState } from "react";
import { cancelDelivery } from "../../data/deliveries";
import { LoaderContext } from "../../contexts";

function CancelDeliveryModal({show, onClose, customer, onComplete}) {
  const [note, setNote] = useState();
  const loader = useContext(LoaderContext);

  const handeleNote = (event) => setNote(event.target.value);
  const onCancelDelivery = async () => {
    loader.addLoader('Cancel Delivery...');
    await cancelDelivery(customer?.phoneNumber, note);
    loader.removeLoader('Cancel Delivery...');
    setNote();
    onComplete();
  };

  const actions = [['Yes, Cancel', onCancelDelivery],
    [`Close`, onClose]];
  return (
    <CommonModal show={show} onClose={onClose} actions={actions}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Cancel Delivery
      </Typography>
      <Typography variant="subtitle1" color="text.secondary"
        component="div">
        {`Are you sure you want to cancel the delivery for 
        ${customer?.name}?`}
      </Typography>
      <TextField value={note} variant="standard" multiline
        fullWidth onChange={handeleNote} label="Cancel Note"/><br/>
    </CommonModal>
  );
};

export default CancelDeliveryModal;
