import { getDoc, getDocs, setDoc } from "@firebase/firestore";

export const getDocuments = async (docs) => {
  const documents = [];
  docs.forEach((doc) => documents.push({id: doc.id, ...doc.data()}));
  return documents;
};

export const getWithChildDocuments = async (docs, child) => {
  return docs.map(async (document) => {
    const childDoc = {...(await getDoc(document[child])).data(),
      id: document[child].id};
    return {...document, [child]: childDoc };
  });
};

export const getDocumentsWithCustomerInfo = async (docs) => {
  const documents = (await docs).map(async (doc) => {
    const customer = (await getDoc(doc.customer)).data();
    return ({id: doc.id, ...doc, customer});
  });
  return Promise.all(documents);
};

export const fetchData = async (setter, fetcher, args=[]) => {
  setter(await fetcher(...args));
};

export const getActualQuantityInLiters = (schedule, user) => {
  if (schedule && 'quantityInLiters' in schedule) {
    return schedule.quantityInLiters;
  } else {
    return user.quantityInLiters;
  }
};

export const fetchDocs = (qry) => {
  let result = [];
  try {
    result = getDocs(qry);
  } catch (error) {
    window['hani_tool'].onError(error.message);
    result = [];
  }

  return result;
};

export const saveDoc = (document, data, more) => {
  let result = [];
  try {
    result = setDoc(document, data, more);
  } catch (error) {
    window['hani_tool'].onError(error.message);
    result = [];
  }

  return result;
};
