import { TextField, Typography } from "@mui/material";
import CommonModal from "./CommonModal";
import { useContext, useState } from "react";
import { StyledTextField } from "./util";
import { LoaderContext } from "../../contexts";
import { markPayment } from "../../data/billing";

function MarkPaymentModal({show, onClose, bill, onComplete}) {
  const {customer} = bill || {};
  const [note, setNote] = useState('');
  const loader = useContext(LoaderContext);

  const handeleNote = (event) => setNote(event.target.value);

  const onMarkPayment = async () => {
    loader.addLoader('Mark Payment...');
    await markPayment(bill.id, {note, status: 'PAID'});
    loader.removeLoader('Mark Payment...');
    setNote('');
    onComplete();
  };

  const actions = [[`Paid`, onMarkPayment],
    ['Close', onClose]];
  return (
    <CommonModal show={show} onClose={onClose} actions={actions}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Mark Payment
      </Typography>
      <StyledTextField value={customer?.name} disabled variant="standard"
        label="Name" fullWidth/><br/>
      <StyledTextField value={bill?.payable} disabled variant="standard"
        label="Payment Amount" fullWidth/><br/>
      <TextField value={note} variant="standard" multiline fullWidth
        onChange={handeleNote} label="Payement Note"/><br/>
    </CommonModal>
  );
};

export default MarkPaymentModal;
