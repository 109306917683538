import { useEffect, useState } from "react";
import { StyledH4 }
  from "../../home/CustomerHome/util";
import { TextField } from "@mui/material";

function RouteAndPoint({routePoint, onChange}) {
  const [route, setRoute] = useState('');
  const [point, setPoint] = useState('');

  const handleRoute = (event) => setRoute(event.target.value);
  const handlePoint = (event) => setPoint(event.target.value);

  useEffect(() => {
    onChange([route, point]);
  }, [route, point]);

  useEffect(() => {
    if (routePoint && routePoint.length === 2) {
      const [propRoute, propPoint] = routePoint;
      setRoute(propRoute);
      setPoint(propPoint);
    }
  }, [routePoint]);

  return (<>
    <StyledH4 text={'Route and Point'}/>
    <TextField required variant="standard" value={route}
      label="Route" onChange={handleRoute}/><br/>
    <TextField required variant="standard" value={point}
      label="Point" onChange={handlePoint}/>
  </>);
};

export default RouteAndPoint;
