import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";

function SearchCustomers({
  filteredCustomers,
  customers,
  onSelection,
}) {
  const [searchKey, setSearchKey] = useState('');

  const onSearchChange = (e) => setSearchKey(e.target.value);

  const onSelect = (_event, value) => {
    if (value) {
      onSelection(value?.id);
    }
  };

  const filterCustomers = (options) => {
    if (filteredCustomers && filteredCustomers.length > 0) {
      return options.reduce((list, cust) => {
        if (!filteredCustomers.includes(cust.id) && cust.label &&
            cust.label.toLowerCase().includes(searchKey.toLowerCase())) {
          list.push(cust);
        }
        return list;
      }, []);
    }
    return options;
  };

  return <Autocomplete
    disablePortal
    id="search-cusomers"
    options={customers}
    clearOnBlur
    filterOptions={filterCustomers}
    renderInput={(params) => <TextField {...params}
      variant="standard" fullWidth
      onChange={onSearchChange}
      label="Search Customers" />}
    onChange={onSelect}
  />;
};

export default SearchCustomers;
