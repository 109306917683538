import { CardContent, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import Search from "../../components/common/Search";
import { getLast3DaysDeliveries } from "../../data/deliveries";
import { CardTitle, ContentTitle,
  StyledCard, TruncatedName, TruncatedText}
  from "../../home/CustomerHome/util";

function Deliveries() {
  const [searchKey, setSearchKey] = useState('');
  const [deliveries, setDeliveries] = useState([]);
  const [filteredDeliveries, setFilteredDeliveries] = useState([]);

  const loadDeliveries= async () =>
    setDeliveries(await getLast3DaysDeliveries());
  useEffect(() => {
    loadDeliveries();
  }, []);

  useEffect(() => {
    if (searchKey) {
      setFilteredDeliveries(deliveries
          .filter(({customer, date, ...delivery}) => (
            customer.name.toLowerCase().includes(searchKey.toLowerCase()) ||
            moment(date.toDate()).format('MMM D, YYYY')
                .toLowerCase().includes(searchKey.toLowerCase()) ||
        delivery.deliveredBy.name.toLowerCase()
            .includes(searchKey.toLowerCase()))));
    } else {
      setFilteredDeliveries(deliveries);
    }
  }, [searchKey, deliveries]);

  return (<>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Deliveries'}/>
      <Search placeholder={'Search'} onSearch={setSearchKey}/>
    </div>
    {filteredDeliveries.map(({customer, ...delivery}) => (
      <StyledCard key={delivery.id}>
        <CardContent>
          <CardTitle>
            <TruncatedName>{customer.name}</TruncatedName>
            <small>{`${delivery.quantityInLiters} L`}</small>
          </CardTitle>
          <Typography variant="subtitle1" color="text.secondary"
            style={{display: 'flex', justifyContent: 'space-between'}}
            component="div">
            <TruncatedText maxWidth={'60%'}>
              {`Delivered: ${moment(delivery.date.toDate())
                  .format('MMM D, YYYY')}`}</TruncatedText>
            <TruncatedText maxWidth={'40%'}>
              {delivery.deliveredBy.name}</TruncatedText>
          </Typography>
        </CardContent>
      </StyledCard>
    ))}
  </>);
};

export default Deliveries;
