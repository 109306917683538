import styled from '@emotion/styled';
import { CheckCircleOutline, DeliveryDining,
  Info,
  Schedule } from '@mui/icons-material';
import { Card, CardActions, IconButton,
  Typography, css, Fab } from '@mui/material';
import moment from 'moment';

export const StyledCardActions = styled(CardActions)`
  justify-content: ${(props) => props.align? props.align : 'center'};
`;

export const StyledCard = styled(Card)`
  margin: 8px 0;
`;

const IconStyle = css`
  vertical-align: middle;
`;

export const StyledSchedule = styled(Schedule)`
  ${IconStyle};
`;

export const StyledDeliveryDining = styled(DeliveryDining)`
  ${IconStyle}
`;

export const StyledInfo = styled(Info)`
  ${IconStyle}
`;

export const StyledCheckCircle = styled(CheckCircleOutline)`
  ${IconStyle}
`;

export const StyledH4 = ({text, style = {}}) => {
  return (<h4 style={{padding: '5px 0', margin: '8px 0', ...style}}>
    {text}</h4>);
};

export const ContentTitle = ({text}) => {
  return (<h3 style={{margin: 0}}>{text}</h3>);
};

export const DeliveriesContainer = styled.div`
  position: relative;
`;

export const TruncatedName = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: ${(props) => props.maxWidth ? props.maxWidth : '80%'};
`;

export const TruncatedText = TruncatedName;

export const CardTitle = ({children}) => {
  return (<Typography variant='h6' component="div"
    style={{display: 'flex', justifyContent: 'space-between',
      alignItems: 'center'}}>
    {children}
  </Typography>);
};

export const disableValueScrollOnNumberFeild = (event) => event.target.blur();

export const CardActionIcon = ({size, color, ariaLabel,
  href, onClick, Icon}) => {
  return (
    <IconButton color={color || 'primary'} aria-label={ariaLabel}
      style={{margin: '0 4px'}}
      size={size || "small"} href={href} onClick={onClick}>
      <Icon />
    </IconButton>
  );
};

export const ScreenAction = styled.div`
  display: flex;
  justify-content: right;
  padding: 8px;
  position: sticky;
  bottom: 0;
`;

export const ScreenActionFab = styled(Fab)`
  margin-left: 10px;
`;

export const isLateToUpdateQuantityForToday = () => {
  const format = "hh:mm:ss";
  const now = Date.now();
  const cutoff = moment('17:30:00', format).toDate().getTime();
  return cutoff < now;
};
