import { Typography } from "@mui/material";
import { contactNumber } from "../../constant";

function DiscontinuedCustomer() {
  return (
    <Typography variant="subtitle1" color="text.secondary"
      component="div">
      {`Thanks for loging. Your services are discontined currently.
      Please talk to our representativ to get your services back.
      For any further queries please call us on `}
      <a href={`tel:${contactNumber}`}>
        {contactNumber}.
      </a>
    </Typography>
  );
};

export default DiscontinuedCustomer;
