import { Button, CardContent,
  Typography } from '@mui/material';
import moment from 'moment';
import { StyledCard as Card, StyledCardActions as CardActions,
  DeliveriesContainer, StyledH4, StyledInfo } from './util';
import ShowMoreOrLess from '../ShowMoreOrLess';
import { useContext, useState } from 'react';
import { getActualQuantityInLiters } from '../../data/util';
import { last } from 'lodash';
import { UserContext } from '../../contexts';

const getSchedule = (schedules, day) => {
  const daySchedules = schedules.filter((schedule) =>
    moment(schedule.date.seconds * 1000).format('yyyy-MM-DD') === day);
  daySchedules.sort((s1, s2) =>
    s1.createdDate.seconds >= s2.createdDate.seconds);
  return last(daySchedules);
};

function UpcomingSchedules({onCancel, onModify, agent, schedules}) {
  const user = useContext(UserContext);
  const [more, showMore] = useState(false);
  const getRemainingSchedules = (more = false) => {
    const scheduleCards = [];
    const date = moment(Date.now());
    let days = more ? 15 : 1;
    while (days > 0) {
      const stringDay = date.add(1, 'days').format('MMM D, YYYY');
      const formatedDay = date.format('yyyy-MM-DD');
      const schedule = getSchedule(schedules, formatedDay);
      const isCancelled = schedule && schedule?.quantityInLiters === 0;
      const liters = getActualQuantityInLiters(schedule, user);
      const scheduleCard = (<Card key={date.toString()}>
        <CardContent style={{paddingBottom: 0}}>
          <Typography variant="subtitle1" color="text.secondary"
            component="div">
            {!isCancelled && `Scheduled: `}
            {isCancelled && `Cancelled: `}
            {stringDay}
          </Typography>
          <Typography variant="body2" noWrap>
            {`HANI Fresh Milk: ${liters} liter(s)`}
            &emsp;
            {`Agent: ${agent?.name}`}
          </Typography>
          { schedule?.note &&
            <Typography variant="body2">
              <StyledInfo fontSize='16'/> Note: {schedule?.note}
            </Typography>
          }
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => onModify(formatedDay, liters)}>
            Modify</Button>
          {!isCancelled && <Button size="small"
            onClick={() => onCancel(formatedDay)}>Cancel</Button>}
          {isCancelled && <Button size="small" disabled>Cancel</Button>}
        </CardActions>
      </Card>);
      days--;
      scheduleCards.push(scheduleCard);
    }
    return scheduleCards;
  };

  return (<DeliveriesContainer>
    <StyledH4 text={'Upcoming Schedules'}/>
    <ShowMoreOrLess onToggle={showMore} moreCount={15}/>
    {getRemainingSchedules(more)}
  </DeliveriesContainer>);
};

export default UpcomingSchedules;
