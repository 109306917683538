import { first } from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import UnavailableService from "../../components/UnavailableService";
import { UserContext } from "../../contexts";
import { getCustomerBills } from "../../data/billing";
import { getUnbilledDeliveries } from "../../data/deliveries";
import PreviousDeliveries from "../../home/CustomerHome/PreviousDeliveries";
import { ContentTitle,
} from "../../home/CustomerHome/util";
import PreviousBillings from "./PreviousBillings";

export const CustomerBillingMode = {
  CUSTOMER_BILL: 0,
  GENERATE_INVOICE: 1,
};

function CustomerBilling({
  mode = CustomerBillingMode.CUSTOMER_BILL, customerId,
  onUnbilledDeliveries, toDate,
  clearCustomer,
}) {
  const user = useContext(UserContext);
  const [unbilledDeliveris, setUnbilledDeliveries] = useState([]);
  const [previousBills, setPreviousBills] = useState([]);
  const [fromDate, setFromDate] = useState();

  useEffect(() => {
    if (fromDate && user) {
      const fetchUnbilled = async () => {
        const custId = mode === CustomerBillingMode.GENERATE_INVOICE ?
          customerId : user.phoneNumber;
        setUnbilledDeliveries(await getUnbilledDeliveries(custId,
            fromDate, toDate));
      };
      fetchUnbilled();
    }
  }, [customerId, fromDate, toDate, user]);

  useEffect(() => {
    const fetchPreviousBills = async () => {
      const custId = mode === CustomerBillingMode.GENERATE_INVOICE ?
        customerId : user.phoneNumber;
      const bills = await getCustomerBills(custId);
      if (bills.length > 0) {
        setFromDate(
            moment(first(bills).toDate.seconds * 1000).add(1, 'days').toDate(),
        );
      } else {
        setFromDate(new Date('04/01/2024'));
      }
      setPreviousBills(bills);
    };
    user && fetchPreviousBills();
  }, [customerId, user]);

  useEffect(() => {
    if (onUnbilledDeliveries && unbilledDeliveris.length > 0) {
      onUnbilledDeliveries(unbilledDeliveris);
    }
  }, [onUnbilledDeliveries, unbilledDeliveris]);

  const createUnbilledDeliveries = () => {
    return <PreviousDeliveries lastDeliveries={unbilledDeliveris}
      mode={'UNBILLED'}/>;
  };

  const hasBillingService = () => {
    return mode === CustomerBillingMode.GENERATE_INVOICE ||
      ( user?.roles?.includes('Customer') && user?.status === 'Subscribed');
  };

  return (<>
    { mode !== CustomerBillingMode.GENERATE_INVOICE &&
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={`Billing`}/>
    </div>}
    <br/>
    {hasBillingService() ?
      (<>
        {createUnbilledDeliveries()}
        {<PreviousBillings bills={previousBills}
          clearCustomer={clearCustomer}/>}
      </>) :
      (<UnavailableService service="Billing"/>)
    }
  </>);
};

export default CustomerBilling;
