import { ErrorOutline } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.error(error, errorInfo);
    this.setState({ hasError: true });
  }

  reloadPage = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div style={styles.container}>
          <ErrorOutline fontSize="large"/>
          <h1 style={styles.heading}>Something went wrong!</h1>
          <Button variant="contained" onClick={this.reloadPage}>
            Try Again</Button>
        </div>
      );
    }

    return this.props.children;
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  icon: {
    fontSize: '3rem',
    color: 'red',
    marginBottom: '1rem',
  },
  heading: {
    fontSize: '1.5rem',
    marginBottom: '1rem',
  },
  button: {
    padding: '0.5rem 1rem',
    fontSize: '1rem',
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default ErrorBoundary;
