import { ExpandLess, ExpandMore, Remove, Route,
  Save } from "@mui/icons-material";
import { CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import SearchCustomers from "../../components/common/SearchCustomers";
import { updateCustomers } from "../../data/deliveryRouting";
import { findCustomers, updateCustomersRoute } from "../../data/user";
import { CardActionIcon, CardTitle, ContentTitle, ScreenAction, ScreenActionFab,
  StyledCard, TruncatedName }
  from "../../home/CustomerHome/util";

function DeliveryRouting() {
  const {state} = useLocation();
  const navigate = useNavigate();
  const {name, id: routeId, customers: prevSelectedCustomers} =
    state?.route || {};

  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  useEffect(() => {
    if (prevSelectedCustomers) {
      setSelectedCustomers(prevSelectedCustomers);
    }
  }, [prevSelectedCustomers]);

  const fetchCustomers = async () => {
    const results = _.compact((await findCustomers())
        .map(({name, id}) => name && ({label: name, id})));
    setCustomers(results);
  };

  useEffect(() => {
    if (!routeId) {
      navigate('/routing');
    }
    fetchCustomers();
  }, [routeId]);

  const onCustomerSelection = (customerId) => {
    setSelectedCustomers((prevCustomers) => [...prevCustomers, customerId]);
  };

  const getName = (customerId) => {
    if (customers?.length > 0) {
      return customers.find(({id}) => id === customerId).label;
    }
  };

  const saveCustomers = async () => {
    await updateCustomers(routeId, selectedCustomers);
    navigate('/routing');
  };

  const applyCustomersRoute = async () => {
    await updateCustomersRoute(selectedCustomers, name);
    navigate('/routing');
  };

  const removeCustomer = (removeId) => {
    setSelectedCustomers(_.remove(selectedCustomers, (id) => id !== removeId));
  };

  const moveUpCustomer = (moveUpId) => {
    const index = selectedCustomers.findIndex((id) => id === moveUpId);
    if (index > 0) {
      const newList = [...selectedCustomers];
      newList[index] = selectedCustomers[index -1];
      newList[index-1] = selectedCustomers[index];
      setSelectedCustomers(newList);
    }
  };

  const moveDownCustomer = (moveDownId) => {
    const index = selectedCustomers.findIndex((id) => id === moveDownId);
    if (index > -1 && index < selectedCustomers.length-1) {
      const newList = [...selectedCustomers];
      newList[index] = selectedCustomers[index+1];
      newList[index+1] = selectedCustomers[index];
      setSelectedCustomers(newList);
    }
  };

  return (<>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={`Modify ${name} Route`}/>
    </div><br/>
    <SearchCustomers filteredCustomers={selectedCustomers}
      customers={customers}
      onSelection={onCustomerSelection}/>
    {selectedCustomers.map((id, index) => (
      <StyledCard key={id}>
        <CardContent>
          <CardTitle>
            <TruncatedName maxWidth={'50%'}>{`${index+1}. `}
              {getName(id) || id}</TruncatedName>
            <div>
              <CardActionIcon aria-label="Move-Up" Icon={ExpandLess}
                onClick={() => moveUpCustomer(id)}/>
              <CardActionIcon aria-label="Move-Down" Icon={ExpandMore}
                onClick={() => moveDownCustomer(id)}/>
              <CardActionIcon aria-label="Remove" Icon={Remove}
                onClick={() => removeCustomer(id)}/>
            </div>
          </CardTitle>
        </CardContent>
      </StyledCard>
    ))}
    <ScreenAction>
      <ScreenActionFab color="primary" aria-label="save"
        onClick={applyCustomersRoute}>
        <Route />
      </ScreenActionFab>

      <ScreenActionFab color="primary" aria-label="save"
        onClick={saveCustomers}>
        <Save />
      </ScreenActionFab>
    </ScreenAction>
  </>);
};

export default DeliveryRouting;
