import { TextField } from "@mui/material";
import { isNaN } from "lodash";
import { useState } from "react";

function PhoneNumber({disabled, onChange, phoneNumber,
  fullWidth, sx, inputProps, onBlur, onFocus}) {
  const [number, setNumber] = useState('');

  const getValue = () => phoneNumber ||
    (number.length > 0 ? `+91${number}`: '');

  const handleNumber = (event) => {
    if (event.target.value.length <= 13) {
      const phno = event.target.value.replace('+91', '');
      if (!isNaN(Number(phno))) {
        setNumber(phno);
        onChange(event.target.value);
      }
    }
  };

  return (
    <TextField required fullWidth={fullWidth} sx={sx}
      inputProps={inputProps}
      onFocus={onFocus} onBlur={onBlur}
      id="phone-number" variant="standard"
      value={getValue()} onChange={handleNumber}
      disabled={disabled || phoneNumber} label={'Phone Number'}/>);
}

export default PhoneNumber;
