import { Typography } from "@mui/material";
import CommonModal from "./CommonModal";
import { createSchedule } from "../../data/schedules";
import { getCurrentUserId } from "../../data/user";
import moment from "moment";
import { useContext } from "react";
import { LoaderContext } from "../../contexts";

function CancelScheduleModal({show, onClose, scheduledDate, onComplete}) {
  const loader = useContext(LoaderContext);
  const onCancelSchedule = async () => {
    loader.addLoader('Cancel Schedule...');
    await createSchedule(getCurrentUserId(),
        new Date(scheduledDate), 0);
    loader.removeLoader('Cancel Schedule...');
    onComplete ? onComplete() : onClose();
  };

  const actions = [['Yes, Cancel', onCancelSchedule],
    [`Close`, onClose]];
  return (
    <CommonModal show={show} onClose={onClose} actions={actions}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Cancel Schedule
      </Typography>
      <Typography variant="subtitle1" color="text.secondary"
        component="div">
        {`Are you sure you want to cancel the schedule for 
        ${moment(scheduledDate).format('LL')}?`}
      </Typography>
    </CommonModal>
  );
};

export default CancelScheduleModal;
