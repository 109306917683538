import { Button, FormControl, TextField } from "@mui/material";
import PhoneNumber from "../components/common/PhoneNumber";
import { useContext, useEffect, useState } from "react";
import UserRole from "../components/common/UserRole";
import CustomerStatus from "../components/common/CustomerStatus";
import Notes from "../components/common/Notes";
import { addNewUser } from "../data/user";
import GeoLocation from "../components/common/GeoLocation";
import { ContentTitle, disableValueScrollOnNumberFeild,
  isLateToUpdateQuantityForToday }
  from "../home/CustomerHome/util";
import { getAdduserNavigation } from "../utils/nav";
import { useLocation, useNavigate } from "react-router-dom";
import RouteAndPoint from "../components/common/RouteAndPoint";
import { first, last } from "lodash";
import { UserContext } from "../contexts";

function AddUpdateUser({isProfile}) {
  const navigate = useNavigate();
  const loggedInUser = useContext(UserContext);

  const {state} = useLocation();
  const [phoneNumber, setPhoneNumber] = useState();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [location, setLocation] = useState([]);
  const [routeAndPoint, setRouteAndPoint] = useState([]);
  const [referredBy, setReferredBy] = useState('-');
  const [quantityInLiters, setQuantityInLiters] = useState();
  const [deliviryTime, setDeliviryTime] = useState('20:00');
  const [roles, setRoles] = useState([]);
  const [notes, setNotes] = useState([]);
  const [status, setStatus] = useState('');

  const handeleName = (event) => setName(event.target.value);
  const handeleAddress = (event) => setAddress(event.target.value);
  const handeleReferredBy = (event) => setReferredBy(event.target.value);

  const handeleQuantity = (event) => setQuantityInLiters(
      Number(event.target.value));
  const handeleDeliviryTime = (event) => setDeliviryTime(event.target.value);

  useEffect(() => {
    let user;
    if (state?.phoneNumber) {
      user = state;
    } else if (isProfile && loggedInUser) {
      user = loggedInUser;
    }

    if (user) {
      setPhoneNumber(user.phoneNumber);
      setName(user.name);
      setAddress(user.address);
      setLocation(user.location);
      setNotes(user.notes || []);
      setDeliviryTime(user.preferredTime || '20:00');
      setQuantityInLiters(user.quantityInLiters);
      setReferredBy(user.referredBy || '-');
      setRoles(user.roles);
      setStatus(user.status);
      setRouteAndPoint([user.route, user.point || 9999]);
    }
  }, [state, isProfile, loggedInUser]);

  const cannotAddUser = () => {
    if (!phoneNumber || !name || !address ||
      !referredBy || roles.length === 0 || (
      roles.includes('Customer') && (!deliviryTime ||
      !quantityInLiters || !status))) {
      return true;
    }
    return false;
  };

  const AddUser = async () => {
    const mergeUserDetails = (state?.phoneNumber || isProfile) ? true : false;

    await addNewUser({
      phoneNumber,
      name,
      address,
      location,
      notes,
      preferredTime: deliviryTime,
      quantityInLiters,
      referredBy,
      roles,
      status,
      route: first(routeAndPoint),
      point: last(routeAndPoint),
    }, mergeUserDetails);
    if (isProfile) {
      window.location.reload();
    } else {
      navigate('/home');
    }
  };

  return (<>
    {!isProfile && <>
      <ContentTitle text={getAdduserNavigation().label}/><br/>
    </>}
    <FormControl fullWidth>
      <PhoneNumber onChange={setPhoneNumber}
        phoneNumber={state?.phoneNumber ||
          (isProfile && loggedInUser?.phoneNumber)}
        disabled={!!state?.phoneNumber ||
        !!(isProfile && loggedInUser?.phoneNumber)}/><br/>
      <TextField required value={name} variant="standard"
        onChange={handeleName} label="Name"/><br/>
      <TextField required value={address} variant="standard"
        multiline
        onChange={handeleAddress} label="Address"/><br/>
      <GeoLocation latLong={location} onChange={setLocation}/><br/>
      {!isProfile && <>
        <TextField required value={referredBy} variant="standard"
          onChange={handeleReferredBy} label="Referred By"/><br/>
        <UserRole roles={roles} onChange={setRoles}/><br/></>
      }
      {roles.includes('Customer') &&
        (<>{
          false && <>
            <TextField required value={deliviryTime} variant="standard"
              type="time"
              onChange={handeleDeliviryTime} label="Delivery Time"/><br/>
          </>}
        <TextField required value={quantityInLiters} variant="standard"
          type="number" onWheel={disableValueScrollOnNumberFeild}
          onChange={handeleQuantity} label="Quantity In Liters (Milk)"
          disabled={isLateToUpdateQuantityForToday()}
          helperText={`Quantity can be updated only before
            5:30PM for the day`}/><br/>
        {!isProfile && <>
          <CustomerStatus status={status} onChange={setStatus}/><br/>
        </>}
        </>)}
      {!isProfile && <>
        <RouteAndPoint routePoint={routeAndPoint}
          onChange={setRouteAndPoint}/><br/>
        <Notes label={'Notes'} values={notes} onChange={setNotes}/><br/></>
      }
      <Button variant="contained" disabled={cannotAddUser()}
        onClick={AddUser}>Update</Button><br/>
    </FormControl>
  </>);
};

export default AddUpdateUser;
