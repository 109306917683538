import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

const StyledButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
`;

function ShowMoreOrLess({onToggle, moreCount}) {
  const [showAll, setShowAll] = useState(false);
  const showMore = () => setShowAll(true);
  const showLess = () => setShowAll(false);

  useEffect(() => {
    onToggle(showAll);
  }, [showAll]);

  if (moreCount <= 1) {
    return null;
  }

  return (<>
    {showAll && <StyledButton size="small" variant='text'
      onClick={showLess}>Show Less(1)</StyledButton>}
    {!showAll && <StyledButton size="small" variant='text'
      onClick={showMore}>Show More({moreCount})</StyledButton>}
  </>);
};

export default ShowMoreOrLess;
