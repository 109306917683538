import {BottomNavigation, BottomNavigationAction,
  ListItemIcon,
  ListItemText,
  Menu, MenuItem} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getUserNavigations } from '../utils/nav';
import { UserContext } from '../contexts';
import { KeyboardArrowUp } from '@mui/icons-material';
import { isDesktop } from '../utils/device';
import { useLocation, useNavigate } from 'react-router-dom';
import { findIndex } from 'lodash';

const StyledBottomNavigation = styled(BottomNavigation)`
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top: lightgray solid 2px;
  overflow: hidden;
  ${!isDesktop() && css`
    position: fixed;
    bottom: 0;
    width: 100%;
  `}
`;

const moreNav = {
  label: 'More',
  Icon: KeyboardArrowUp,
};

function Navigation() {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentNav, setCurrentNav] = useState(-1);
  const [primaryNavs, setPrimaryNavs] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [secondaryNavs, setSecondaryNavs] = useState([]);
  const [moreElement, setMoreElement] = useState(null);

  const closeMore = () => setShowMore(false);

  useEffect(() => {
    const navigations = getUserNavigations(user);
    if (navigations.length <= 4) {
      setPrimaryNavs(navigations);
      setSecondaryNavs([]);
    } else {
      const moreNavs = navigations.splice(3);
      setPrimaryNavs(navigations);
      setSecondaryNavs(moreNavs);
    }
  }, [user]);

  useEffect(() => {
    const primaryIndex = findIndex(primaryNavs, (nav) =>
      nav.path === location.pathname);
    if (primaryIndex === -1) {
      const secondaryNavsIndex = findIndex(secondaryNavs, (nav) =>
        nav.path === location.pathname);
      if (secondaryNavsIndex !== -1) {
        setCurrentNav(secondaryNavsIndex + 4);
      }
    } else {
      setCurrentNav(primaryIndex);
    }
  }, [location, secondaryNavs, primaryNavs]);

  useEffect(() => {
    if (currentNav === 3 && secondaryNavs.length === 0) {
      navigate(primaryNavs[currentNav]?.path);
    } else if (currentNav === moreNav.label) {
      setShowMore(true);
    } else {
      currentNav < 3 ? navigate(primaryNavs[currentNav]?.path) :
      navigate(secondaryNavs[currentNav-4]?.path);
    }
  }, [currentNav]);

  const onNavChange = (event, newNav) => {
    if (newNav === 3 && secondaryNavs.length > 0) {
      setMoreElement(event.currentTarget);
      setShowMore(true);
    } else {
      setCurrentNav(newNav);
    }
  };

  return (<div>
    <StyledBottomNavigation
      showLabels
      value={currentNav}
      onChange={onNavChange}
    >
      {
        primaryNavs
            .map((nav) => (
              <BottomNavigationAction label={nav.label}
                icon={<nav.Icon/>} key={nav.label}/>
            ))
      }
      { secondaryNavs.length > 0 && <BottomNavigationAction
        label={moreNav.label} icon={<moreNav.Icon/>} key={moreNav.label}/>}
    </StyledBottomNavigation>
    <Menu
      id="basic-menu"
      anchorEl={moreElement}
      open={showMore}
      onClose={closeMore}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {secondaryNavs.map((nav, index) => (
        <MenuItem onClick={(event) => {
          onNavChange(event, index+4);
          closeMore();
        }}
        selected={currentNav === 4+index}
        key={nav.label}>
          <ListItemIcon><nav.Icon/></ListItemIcon>
          <ListItemText>{nav.label}</ListItemText>
        </MenuItem>
      ))}
    </Menu>
  </div>
  );
};

export default Navigation;
