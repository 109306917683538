import { DeliveriesContainer, StyledH4 } from '../CustomerHome/util';
import { useState } from 'react';
import Delivery from './Delivery';
import ShowMoreOrLess from '../ShowMoreOrLess';
import { first } from 'lodash';

function Deliveries({ deliveries, currentDeliveryIndex, onCancel,
  onDelivery, completedDeliveries }) {
  const [more, showMore] = useState(false);

  const getRemainingDeliveries = () => {
    const deliveryElements = [];
    deliveries.forEach((delivery, index) => {
      deliveryElements.push(<Delivery delivery={delivery}
        key={delivery.id + '-'+index}
        onCancel={onCancel} onDelivery={onDelivery}/>);
    });

    return more ? deliveryElements : [first(deliveryElements)];
  };

  const getCompletedDeliveries = () => {
    const deliveryElements = [];
    completedDeliveries
        .forEach((_delivery, index) => {
          deliveryElements.push(<Delivery delivery={_delivery}
            key={_delivery.customer.id + '-' +index}
            isDelivered
            onCancel={onCancel} onDelivery={onDelivery}/>);
        });

    return deliveryElements;
  };

  return (<DeliveriesContainer>
    <StyledH4 text={'Today Deliveries'}/>
    <ShowMoreOrLess onToggle={showMore}
      moreCount={deliveries.length + completedDeliveries.length}/>
    {getRemainingDeliveries()}
    {(more || deliveries.length === currentDeliveryIndex) &&
      getCompletedDeliveries()}
  </DeliveriesContainer>);
};

export default Deliveries;
