import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SearchCustomers from "../../components/common/SearchCustomers";
import { findCustomers } from "../../data/user";
import { ContentTitle }
  from "../../home/CustomerHome/util";
import CustomerBilling, { CustomerBillingMode } from "../customerBilling";
import BillingDetails from "./BillingDetails";

function GenerateInvoice() {
  const [customers, setCustomers] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [billToDate, setBillToDate] = useState();

  const [selectedCustomerId, setSelectedCustomerId] = useState();
  const [selectedCustomerDeleveries, setSelectedCustomerDeleveries] =
    useState();

  const changeSelectedCustomer = (customerId) => {
    setSelectedCustomerDeleveries();
    setBillToDate();
    setSelectedCustomerId(customerId);
  };

  const fetchCustomers = async () => {
    const list = await findCustomers();
    const results = list
        .map(({name, id}) => ({label: name, id}));
    setAllCustomers(list);
    setCustomers(results);
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const showCustomerDetails = () => {
    const customer = allCustomers.find(({id}) => id === selectedCustomerId);
    return customer && (<>
      <br/>
      <Typography variant="subtitle1" color="text.secondary">
        Customer Name: {customer.name} <br/>
        Phone No.: {customer.phoneNumber}
      </Typography>
      <br/>
    </>);
  };

  const showBillingDetails = () => {
    const customer = allCustomers.find(({id}) => id === selectedCustomerId);
    return <BillingDetails deliveries={selectedCustomerDeleveries}
      onBillToDateChange={setBillToDate}
      customer={customer}
      clearCustomer={setSelectedCustomerId}/>;
  };

  return (<>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Generate Customer Invoice'}/>
    </div>
    <SearchCustomers filteredCustomers={customers}
      customers={customers}
      onSelection={changeSelectedCustomer }/>
    {
      selectedCustomerId && (<>
        {showCustomerDetails()}
        {showBillingDetails()}
        <CustomerBilling mode={CustomerBillingMode.GENERATE_INVOICE}
          customerId={selectedCustomerId} toDate={billToDate}
          onUnbilledDeliveries={setSelectedCustomerDeleveries}
          clearCustomer={setSelectedCustomerId}/>
      </>)
    }
    <br/>
  </>);
};

export default GenerateInvoice;
