import { useEffect, useState, Fragment } from "react";
import _ from 'lodash';
import Product from "../../components/Product";
import { getAllProducts } from "../../data/products";
import { ContentTitle } from "../../home/CustomerHome/util";
import ShowProductInterestModal
  from "../../components/modals/ShowProductInterestModal";
import { createInterestedProduct } from "../../data/interestedProducts";

function Products() {
  const [products, setProducts] = useState([]);
  const [interestedProduct, setInterestedProduct] = useState();

  useEffect(() => {
    (async () => (setProducts(await getAllProducts())))();
  }, []);

  const onAction = async (type, product) => {
    switch (_.toLower(type)) {
      case 'interested':
        await createInterestedProduct(product);
        setInterestedProduct(product);
        break;
      default:
        break;
    }
  };

  const clearModals = () => {
    setInterestedProduct();
  };

  return (<>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'More Products'}/>
    </div>
    {_.orderBy(products, ['id', 'name'])
        .map((product) => (<Fragment key={product.id + product.name}>
          <br/>
          <Product product={product}
            onAction={onAction}/>
        </Fragment>))}
    <br/>
    {interestedProduct &&
      <ShowProductInterestModal show={!!interestedProduct}
        onClose={clearModals} product={interestedProduct}/>}
  </>);
};

export default Products;
