import { collection, doc,
  orderBy, query, setDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { v4 } from "uuid";
import { getCurrentUserRef, getUserRef } from "./user";
import { getWithChildDocuments, getDocuments } from "./util";
import { getDocs } from './';

const collectionName = 'deliveryRouting';

export const createRoute = async (name, agentId) => {
  window['hani_tool'].addLoader('Creating route...');
  const docId = v4();
  const createdBy = getCurrentUserRef();
  const createdDate = new Date();
  const modifiedBy = createdBy;
  const modifiedDate = createdDate;

  await setDoc(doc(db, collectionName, docId), {
    agent: getUserRef(agentId),
    name,
    customes: [],
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
  });
  window['hani_tool'].removeLoader('Creating route...');
};

export const updateRoute = async (docId, name, agentId) => {
  window['hani_tool'].addLoader('Updating route...');
  await setDoc(doc(db, collectionName, docId),
      {
        name,
        agent: getUserRef(agentId),
        modifiedBy: getCurrentUserRef(),
        modifiedDate: new Date(),
      }, {merge: true});
  window['hani_tool'].removeLoader('Updating route...');
};

export const updateCustomers = async (docId, customers) => {
  window['hani_tool'].addLoader('Updating customer route...');
  await setDoc(doc(db, collectionName, docId),
      {
        customers,
        modifiedBy: getCurrentUserRef(),
        modifiedDate: new Date(),
      }, {merge: true});
  window['hani_tool'].removeLoader('Updating customer route...');
};

export const getAllRoutes = async () => {
  window['hani_tool'].addLoader('Fetching all routes...');
  const qry = query(collection(db, collectionName), orderBy('name', 'desc'));
  const docs = await getDocuments(await getDocs(qry));
  const docsWithAgent = await getWithChildDocuments(docs, 'agent');
  const finalDocs = await Promise.all(docsWithAgent);
  window['hani_tool'].removeLoader('Fetching all routes...');
  return finalDocs;
};
