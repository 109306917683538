import { Button, CardContent, Rating,
  Typography } from '@mui/material';
import moment from 'moment';
import { StyledCard as Card, StyledCardActions as CardActions,
  DeliveriesContainer, StyledH4 } from './util';
import ShowMoreOrLess from '../ShowMoreOrLess';
import { useContext, useState } from 'react';
import { UserContext } from '../../contexts';

const previousDeliveries = 'Previous Deliveries';
const unbilledDeliveries = 'Unbilled Deliveries';
const MODE = {
  PREV15: 'PREV15',
  UNBILLED: 'UNBILLED',
};

function PreviousDeliveries({onRateDelivery, lastDeliveries, mode}) {
  const user = useContext(UserContext);
  const [more, showMore] = useState(false);

  const getPastDeliveries = (more = false) => {
    const deliveries = [];
    const items = more ? lastDeliveries.length : 1;
    let iteration = 0;
    while (iteration < items) {
      const delivery = lastDeliveries[iteration];
      const { date, quantityInLiters, rating, createdDate } = delivery;
      const day = new Date(createdDate.seconds * 1000);
      const deliveryCard = (<Card key={`past-del-${date.seconds}`}>
        <CardContent>
          <Typography variant="subtitle1" color="text.secondary"
            component="div">
            { quantityInLiters > 0 ? 'Devlivered: ': 'Cancelled: '}
            {moment(day).format('llll')}
          </Typography>
          <Typography variant="body2">
            HANI Milk: {quantityInLiters || 0} liter(s)
            &emsp;
            {user.id !== delivery?.deliveredBy?.id &&
              `Agent: ${delivery?.deliveredBy?.name}`}
          </Typography>
        </CardContent>
        { onRateDelivery && <CardActions>
          { !!rating && <Rating name={'rating-controlled'}
            value={rating}/>}
          { quantityInLiters === 0 && <Button size="small" disabled>
            Cancelled</Button>}
          { !rating && quantityInLiters > 0 &&
            <Button size="small" onClick={() => onRateDelivery(delivery)}>
              Rate</Button>}
        </CardActions> }
      </Card>);
      deliveries.push(deliveryCard);
      iteration += 1;
    }
    return deliveries;
  };

  if (lastDeliveries.length < 1) {
    return null;
  }

  return (<DeliveriesContainer>
    <StyledH4 text={ mode === MODE.UNBILLED ? unbilledDeliveries :
      previousDeliveries}/>
    <ShowMoreOrLess onToggle={showMore} moreCount={lastDeliveries.length}/>
    {lastDeliveries.length > 0 && getPastDeliveries(more)}
  </DeliveriesContainer>);
};

export default PreviousDeliveries;
