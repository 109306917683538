import { collection, doc,
  orderBy, query,
} from "firebase/firestore";
import { db } from "../firebase";
import { getDocuments } from "./util";
import { getDocs } from './';

const collectionName = 'products';

export const getAllProducts = async () => {
  window['hani_tool'].addLoader('Fetching all products...');
  const qry = query(collection(db, collectionName), orderBy('name', 'desc'));
  const docs = await getDocuments(await getDocs(qry));
  window['hani_tool'].removeLoader('Fetching all products...');
  return docs;
};

export const getProductRef = (id) => {
  window['hani_tool'].addLoader('getting product ref...');
  const docmnt = doc(db, collectionName, id);
  window['hani_tool'].removeLoader('getting product ref...');
  return docmnt;
};
