import { collection, doc, getDoc, orderBy, query,
  setDoc, where } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import { getDocuments } from "./util";
import { first } from "lodash";
import { getDocs } from './';

const collectionName = 'user';

export const getCurrentUserId = () => getAuth().currentUser.phoneNumber;

export const addUser = async (userDetails, merge = false) => {
  window['hani_tool'].addLoader('updating user...');
  await setDoc(doc(db, collectionName, getCurrentUserId()),
      userDetails, {merge});
  window['hani_tool'].removeLoader('updating user...');
};

export const addNewUser = async (userDetails, merge = false) => {
  window['hani_tool'].addLoader('updating new user...');
  await setDoc(doc(db, collectionName, userDetails.phoneNumber),
      userDetails, {merge});
  window['hani_tool'].removeLoader('updating new user...');
};

export const fetchALlCustomers = async () => {
  window['hani_tool'].addLoader('Fetching all customers...');
  const qry = query(collection(db, collectionName),
      where("roles", "array-contains", "Customer"));
  const documents = getDocuments(await getDocs(qry));
  window['hani_tool'].removeLoader('Fetching all customers...');
  return documents;
};

export const fetchALlStaff = async () => {
  window['hani_tool'].addLoader('Fetching all staffs...');
  const qry = query(collection(db, collectionName),
      where("roles", "array-contains-any", ["Manager", "Delivery Agent"]));
  const documents = getDocuments(await getDocs(qry));
  window['hani_tool'].removeLoader('Fetching all staffs...');
  return documents;
};

export const fetchAllDeliveryAgents = async () => {
  window['hani_tool'].addLoader('Fetching all agents...');
  const qry = query(collection(db, collectionName),
      where("roles", "array-contains-any", ["Delivery Agent"]));
  const documents = getDocuments(await getDocs(qry));
  window['hani_tool'].removeLoader('Fetching all agents...');
  return documents;
};

export const updateUser = async (userDetails) => addUser(userDetails, true);

export const getLoggedInUserInfo = async () => {
  window['hani_tool'].addLoader('get logged in user...');
  const result = await getDoc(doc(db,
      collectionName,
      getCurrentUserId()));
  window['hani_tool'].removeLoader('get logged in user...');
  return result;
};

export const getUserRef = (customerId) => {
  window['hani_tool'].addLoader('get user ref...');
  const result = doc(db, collectionName, customerId);
  window['hani_tool'].removeLoader('get user ref...');
  return result;
};

export const getCurrentUserRef = () => getUserRef(getCurrentUserId());

export const getMyRouteCustomers = async (route) => {
  window['hani_tool'].addLoader('get my route customers...');
  const qry = query(collection(db, collectionName),
      where("roles", "array-contains", "Customer"),
      where("status", "==", "Subscribed"),
      where("route", "==", route), orderBy('point'));
  const documents = await getDocuments(await getDocs(qry));
  window['hani_tool'].removeLoader('get my route customers...');
  return documents;
};

export const getRouteAgent = async (route) => {
  window['hani_tool'].addLoader('get route agent...');
  const qry = query(collection(db, collectionName),
      where("roles", "array-contains", "Delivery Agent"),
      where("route", "==", route));
  const documents = await getDocuments(await getDocs(qry));
  window['hani_tool'].removeLoader('get route agent...');
  return first(documents);
};

export const findCustomers = async () => {
  window['hani_tool'].addLoader('Search Customers...');
  const qry = query(collection(db, collectionName));
  const documents = await getDocuments(await getDocs(qry));
  window['hani_tool'].removeLoader('Search Customers...');
  return documents;
};

export const updateCustomersRoute = async (customers, route) => {
  window['hani_tool'].addLoader('Updating customers Route...');
  await Promise.all(customers.map((custId, index) => {
    if ((index+'').length < 5) {
      index = index + 1;
      index = (new Array(5-(index+'').length)).fill('0').join('')+index;
    }
    return setDoc(doc(db, collectionName, custId),
        {route, point: index }, {merge: true});
  }));
  window['hani_tool'].removeLoader('Updating customers Route...');
};
