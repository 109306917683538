import { Rating, TextField, Typography } from "@mui/material";
import styled from "@emotion/styled";
import CommonModal from "./CommonModal";
import { useContext, useEffect, useState } from "react";
import { disableValueScrollOnNumberFeild } from "../../home/CustomerHome/util";
import { StyledTextField } from "./util";
import moment from "moment";
import { addFeedback } from "../../data/deliveries";
import { LoaderContext } from "../../contexts";

const RatingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

function RateDeliveryModal({show, onClose, delivery, onComplete}) {
  const [date, setDate] = useState(moment().format('yyyy-MM-DD'));
  const [feedback, setFeedback] = useState();
  const [rating, setRating] = useState();
  const loader = useContext(LoaderContext);

  const handeleFeedback = (event) => setFeedback(event.target.value);
  const handeleRating = (event) => setRating(event.target.value);

  useEffect(() => {
    if (delivery?.date) {
      setRating();
      setFeedback();
      setDate(delivery?.date);
    }
  }, [delivery]);

  const onRating = async () => {
    loader.addLoader('Rate Delivery...');
    await addFeedback(delivery.id, rating, feedback);
    loader.removeLoader('Rate Delivery...');
    setFeedback();
    onComplete();
  };

  const actions = [[`Rate`, onRating, !rating],
    ['Close', onClose]];
  return (
    <CommonModal show={show} onClose={onClose} actions={actions}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Rate the Delivery
      </Typography>
      <StyledTextField disabled variant="standard"
        label="Date" fullWidth type="text"
        defaultValue={moment(date).format('LL')}/><br/>
      <StyledTextField value={delivery?.quantityInLiters} variant="standard"
        type="number" disabled onWheel={disableValueScrollOnNumberFeild}
        label="Quantity In Liters" fullWidth/><br/><br/>
      <RatingWrapper>
        <Rating name={'add-rating'} value={rating}
          onChange={handeleRating} size={"large"}/>
      </RatingWrapper>
      <br/>
      <TextField value={feedback} variant="standard" multiline fullWidth
        onChange={handeleFeedback} label="Delivery Feedback"/><br/>
    </CommonModal>
  );
};

export default RateDeliveryModal;
