import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";

const StyledBackground = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  position: absolute;
  top: 0;
  background-color: #dcedc8;
  opacity: 0.5;
`;

const StyledDiv = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
function Loader({show}) {
  if (!show) {
    return null;
  }

  return (<>
    <StyledBackground />
    <StyledDiv>
      <CircularProgress />
    </StyledDiv>
  </>);
};

export default Loader;
