import styled from '@emotion/styled';
import Header from './Header';
import { isDesktop } from '../utils/device';
import { css } from '@emotion/react';
import haniImage from '../assets/images/hani.jpeg';
import { lightGreen } from '@mui/material/colors';
import Loader from './common/Loader';
import { useContext } from 'react';
import { LoaderContext } from '../contexts';

const ContainerDiv = styled.div`
  background-color: #f1f5e3;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const BackgroundDiv = styled.div`
  background-image: url(${haniImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  filter: blur(8px);
`;

const BodyDiv = styled.div`
  background-color: ${lightGreen['100']};
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${isDesktop() && css`
    height: 85%;
    max-width: 4in;
    border-radius: 8px;
    z-index: 1;
    border: lightgray 1px solid;
  `}
`;

function Container({children}) {
  const loader = useContext(LoaderContext);
  return (<>
    <ContainerDiv>
      {isDesktop() && <BackgroundDiv/>}
      <BodyDiv>
        <Header/>
        {children}
      </BodyDiv>
    </ContainerDiv>
    <Loader show={loader?.isLoading}/>
  </>);
};

export default Container;
