import { SearchRounded } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const StyledSearch = styled(SearchRounded)`
  cursor: pointer;
`;

function Search({placeholder, onSearch}) {
  const [show, setShow] = useState(false);
  const textBox = useRef();
  const [searchKey, setSearchKey] = useState('');

  const showSearch = () => {
    setShow(true);
    textBox.current.focus();
  };

  const hideSearch = () => setShow(false);

  const onChange = (event) => {
    setSearchKey(event.target.value);
  };

  useEffect(() => {
    onSearch(searchKey);
  }, [searchKey]);

  return (
    <>
      {!show && <StyledSearch onClick = {showSearch}
        sx={{ color: 'action.active', mr: 1, my: 0.5 }} />}
      {<Box sx={{ display: show ? 'flex' : 'none', alignItems: 'flex-end',
        maxWidth: '50%'}}>
        <TextField id="input-with-sx" size={'small'}
          placeholder={placeholder || ''}
          onChange={onChange} ref={textBox}
          variant="standard" value={searchKey}/>
        <StyledSearch sx={{ color: 'action.active', mr: 1, my: 0.5 }}
          onClick={hideSearch}/>
      </Box>}
    </>
  );
};

export default Search;
