import { collection, doc, getCountFromServer, query,
  setDoc, where, orderBy, limit, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { v4 } from "uuid";
import { getCurrentUserId, getCurrentUserRef, getUserRef } from "./user";
import moment from "moment";
import { getDocuments, getDocumentsWithCustomerInfo } from "./util";
import { getDocs } from './';

const collectionName = 'deliveries';

export const createDelivery = async (customerId, quantityInLiters, note='') => {
  window['hani_tool'].addLoader('Adding delivery...');
  await setDoc(doc(db, collectionName, v4()), {
    customer: getUserRef(customerId),
    date: new Date(moment().format('yyyy-MM-DD')),
    quantityInLiters,
    createdBy: getCurrentUserRef(),
    createdDate: new Date(),
    note,
    billingStatus: '',
  });
  window['hani_tool'].removeLoader('Adding delivery...');
};

export const cancelDelivery = async (customerId, note) => {
  await createDelivery(customerId, 0, note);
};

export const addFeedback = async (deliveryId, rating, feedback) => {
  window['hani_tool'].addLoader('Adding delivery feedback...');
  await setDoc(doc(db, collectionName, deliveryId), {
    rating: Number(rating),
    modifiedBy: getCurrentUserRef(),
    modifiedDate: new Date(),
    feedback,
  }, {merge: true});
  window['hani_tool'].removeLoader('Adding delivery feedback...');
};

export const getMyTodayDeliveries = async (filterByAgent = true) => {
  window['hani_tool'].addLoader('Fetching today deliveries...');
  const today = new Date(moment().format('yyyy-MM-DD'));
  let qry = query(collection(db, collectionName), where("date", '==', today),
      orderBy('createdDate', 'desc'));
  if (filterByAgent) {
    qry = query(collection(db, collectionName), where("date", '==', today),
        where('createdBy', '==', getCurrentUserRef()),
        orderBy('createdDate', 'desc'));
  }
  const docs = await getDocuments(await getDocs(qry));
  const result = await getDocumentsWithCustomerInfo(docs);
  window['hani_tool'].removeLoader('Fetching today deliveries...');
  return result;
};

export const getMyTodayDeliveriesCount = async () => {
  window['hani_tool'].addLoader('Fetching today deliveries count...');
  const today = new Date(moment().format('yyyy-MM-DD'));
  const qry = query(collection(db, collectionName), where("date", '==', today),
      where('createdBy', '==', getCurrentUserRef()));
  const snapshot = await getCountFromServer(qry);
  window['hani_tool'].removeLoader('Fetching today deliveries count...');
  return snapshot.data().count;
};

export const getLast15DeliveriesOfCurrentCustomer = async () => {
  window['hani_tool'].addLoader('Fetching last 15 deliveries...');
  const qry = query(collection(db, collectionName),
      where('customer', '==', getCurrentUserRef()),
      orderBy('date', 'desc'), limit(15));
  const result = getDeliveriesFromQuery(qry);
  window['hani_tool'].removeLoader('Fetching last 15 deliveries...');
  return result;
};

export const getDeliveries = async (customerId, fromDate, toDate) => {
  return await getUnbilledDeliveries(customerId, fromDate, toDate);
};

export const getUnbilledDeliveries = async (customerId, fromDate, toDate) => {
  fromDate = fromDate || new Date('04/01/2024');
  toDate = toDate || moment().endOf('month').toDate();
  let results = [];
  window['hani_tool'].addLoader('Fetching all unbilled deliveries...');
  try {
    const customer = customerId ? getUserRef(customerId) : getCurrentUserId();
    const qry = query(collection(db, collectionName),
        where('customer', '==', customer),
        where('date', '>=', fromDate),
        where('date', '<=', toDate),
        orderBy('date', 'desc'));
    results = await getDeliveriesFromQuery(qry);
  } catch (error) {
    window['hani_tool'].onError(error.message);
  } finally {
    window['hani_tool'].removeLoader('Fetching all unbilled deliveries...');
  }

  return results;
};

const getDeliveriesFromQuery = async (qry) => {
  const documents = (await getDocuments(await getDocs(qry)))
      .map(async (document) => {
        const deliveredBy = (await getDoc(document.createdBy)).data();
        return {...document, deliveredBy };
      });
  return Promise.all(documents);
};

export const getLast3DaysDeliveries = async () => {
  window['hani_tool'].addLoader('Fetching all deliveries...');
  const dayBeforeYest = new Date(moment().subtract(5, 'days')
      .format('yyyy-MM-DD'));
  const qry = query(collection(db, collectionName),
      where('date', '>=', dayBeforeYest),
      orderBy('date', 'desc'));
  const docs = await getDocuments(await getDocs(qry));
  const docsWithCustomers = await getDocumentsWithCustomerInfo(docs);
  const docsWithCustomersAndDeliveryAgent = docsWithCustomers
      .map(async (document) => {
        const deliveredBy = (await getDoc(document.createdBy)).data();
        return {...document, deliveredBy };
      });
  const finalDocs = await Promise.all(docsWithCustomersAndDeliveryAgent);
  window['hani_tool'].removeLoader('Fetching all deliveries...');
  return finalDocs;
};

export const fetchBillDeliveries = async (bill) => {
  window['hani_tool'].addLoader('download bill...');
  const fromDate = new Date(bill.fromDate.seconds * 1000);
  const toDate = new Date(bill.toDate.seconds * 1000);
  const deliveries = await getDeliveries(
      bill.customer.phoneNumber, fromDate, toDate);
  window['hani_tool'].removeLoader('download bill...');
  return deliveries;
};
