import { Typography } from "@mui/material";
import { contactNumber } from "../../constant";

function EnquiredCustomer() {
  return (
    <Typography variant="subtitle1" color="text.secondary"
      component="div">
      {`Thanks for showing interest in HANI Fresh Milk.
          Shortly, our representative will schedule sample delivers.
          For any further queries please call us on `}
      <a href={`tel:${contactNumber}`}>
        {contactNumber}.
      </a>
    </Typography>
  );
};

export default EnquiredCustomer;
