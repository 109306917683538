import styled from '@emotion/styled';
import Container from './Container';
import {spacing} from '../constant';
import Navigation from './Navigation';
import ErrorBoundary from './ErrorBoundary';

const headerTotalHeight = spacing.header.height +
  spacing.header.paddingBottom + spacing.header.paddingTop +
  spacing.header.separatorHeight;

const navbarHeight = 58;

const getBodyHeight = (props) => headerTotalHeight +
  (props.hasNavBar ? navbarHeight: 0);

const StyledDiv = styled.div`
  height: calc(100% - ${(props) => getBodyHeight(props)}px);
  padding: 0 16px;
  overflow: auto;
  position: relative;
`;

function Content({children, hasNavBar}) {
  return (<ErrorBoundary>
    <Container>
      <StyledDiv hasNavBar={hasNavBar}>
        {children}
      </StyledDiv>
      {hasNavBar && <Navigation />}
    </Container>
  </ErrorBoundary>);
};

export default Content;
