import {useContext, useEffect} from 'react';
import {UserContext} from '../contexts';
import {useNavigate} from 'react-router-dom';
import CustomerHome from './CustomerHome';
import DeliveryAgentHome from './DeliveryAgentHome';
import { ContentTitle, StyledH4 } from './CustomerHome/util';
import UserRole from './UserRole';
import ManagerHome from './ManagerHome';

function Home() {
  const user = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const callback = () => {
      if (!user) {
        navigate('/login');
      }
    };

    const timeout = setTimeout(callback, 2000);
    return () => clearTimeout(timeout);
  }, [user]);

  if (!user) {
    return null;
  }

  return (<div>
    <ContentTitle text={`Hello, ${user?.name || ""}`}/>
    <div>
      <UserRole />
      <StyledH4 text={'Good Day!!!'}/>
    </div>
    {user?.currentRole === 'Customer' && <CustomerHome />}
    {user?.currentRole === 'Delivery Agent' && <DeliveryAgentHome />}
    {user?.currentRole === 'Manager' && <ManagerHome />}
    {user?.currentRole === 'Admin' && 'Admin Home'}
  </div>);
};

export default Home;
