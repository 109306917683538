import { TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { disableValueScrollOnNumberFeild } from "../../home/CustomerHome/util";
import CommonModal from "./CommonModal";
import { StyledTextField } from "./util";
import moment from "moment";
import { createSchedule } from "../../data/schedules";
import { isNumber } from "lodash";
import { LoaderContext } from "../../contexts";

const isValidQuantity = (quantity) => quantity >= 0;
const isValidDate = (date) => date.getTime() > moment().subtract(1, 'days')
    .toDate().getTime();
const isValidDateAndQuantity = (date, quantity) =>
  isValidDate(date) && isValidQuantity(quantity);

function ScheduleModal({show, onClose, name, phoneNumber, liters,
  scheduledDate, onComplete}) {
  const [date, setDate] = useState(moment().format('yyyy-MM-DD'));
  const [quantityInLiters, setQuantityInLiters] = useState(0);
  const [note, setNote] = useState('');
  const loader = useContext(LoaderContext);

  const handeleNote = (event) => setNote(event.target.value);

  useEffect(() => {
    setQuantityInLiters(liters);
  }, [liters]);

  useEffect(() => {
    if (scheduledDate) {
      setDate(scheduledDate);
    }
  }, [scheduledDate]);

  const handleDate = (event) => setDate(event.target.value);
  const handeleQuantity = (event) => setQuantityInLiters(event.target.value);

  const onModify = async () => {
    await onCancelSchedule(Number(quantityInLiters));
  };

  const onCancelSchedule = async (quantity) => {
    loader.addLoader('Modify Schedule...');
    await createSchedule(phoneNumber, new Date(date),
      isNumber(quantity) ? quantity : 0, note);
    loader.removeLoader('Modify Schedule...');
    onComplete ? onComplete() : onClose();
  };

  const areValidInputs = isValidDateAndQuantity(new Date(date),
      quantityInLiters);
  const actions = [['Modify', onModify, !areValidInputs],
    ['Cancel Schedule', onCancelSchedule, !areValidInputs]];
  return (
    <CommonModal show={show} onClose={onClose} actions={actions}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Modify Schedule
      </Typography>
      <StyledTextField defaultValue={phoneNumber} disabled variant="standard"
        label="Phone Number" fullWidth/><br/>
      <StyledTextField defaultValue={name} disabled variant="standard"
        label="Name" fullWidth/><br/>
      <StyledTextField value={date} variant="standard" label="Schedule Date"
        disabled={!!scheduledDate}
        onChange={handleDate} fullWidth type="date"/><br/>
      <StyledTextField required value={quantityInLiters} variant="standard"
        type="number" onWheel={disableValueScrollOnNumberFeild}
        onChange={handeleQuantity} label="Quantity In Liters" fullWidth/><br/>
      <TextField value={note} variant="standard" multiline fullWidth
        onChange={handeleNote} label="Schedule Note"/><br/>
    </CommonModal>
  );
};

export default ScheduleModal;
