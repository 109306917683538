import TodaySchedule from './TodaySchedule';
import RemainingDeliveries from './RemaingDeliveries';
import { useContext, useEffect, useState } from 'react';
import {LoaderContext, UserContext} from '../../contexts';
import { getMyRouteCustomers } from '../../data/user';
import { getTodaySchedules } from '../../data/schedules';
import { last, orderBy, sumBy } from 'lodash';
import CancelDeliveryModal from '../../components/modals/CancelDeliveryModal';
import MarkDeliveryModal from '../../components/modals/MarkDeliveryModal';
import { getMyTodayDeliveries, getMyTodayDeliveriesCount }
  from '../../data/deliveries';
import Deliveries from './Deliveries';

function DeliveryAgentHome() {
  const user = useContext(UserContext);
  const loader = useContext(LoaderContext);
  const [hasRouteAssigned, setHasNoRouteAssigned] = useState(false);
  const [routeCustomers, setRouteCustomers] = useState([]);
  const [todaySchedules, setTodaySchedules] = useState([]);
  const [currentCustomerIndex, setCurrentCustomerIndex] = useState(0);
  const [todayDeliveries, setTodayDeliveris] = useState([]);
  const [currentDelivery, setCurrentDelivery] = useState();

  const [completedDeliveries, setCompletedDeliveries] = useState([]);

  const [showCancel, setShowCancel] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  const showCancelModal = (delivery) => {
    setCurrentDelivery(delivery);
    setShowCancel(true);
  };
  const showDeliveryModal = (delivery) => {
    setCurrentDelivery(delivery);
    setShowDelivery(true);
  };

  const handleCloseModal = async () => {
    setShowCancel(false);
    setShowDelivery(false);
    setCurrentDelivery();
    setCompletedDeliveries(await getMyTodayDeliveries());
  };

  useEffect(() => {
    const fethTodaySchedules = async () => {
      const schedules = await getTodaySchedules();
      const uniqueSchedules = schedules.reduce((uniques, schedule) => {
        const lastSchedule = last(uniques);
        if (!lastSchedule || (lastSchedule &&
            lastSchedule.customer.phoneNumber !==
            schedule.customer.phoneNumber)) {
          uniques.push(schedule);
        }
        return uniques;
      }, []);
      setTodaySchedules(uniqueSchedules);
      setCompletedDeliveries(await getMyTodayDeliveries());
    };
    loader.addLoader('Loading Agent schedules...');
    fethTodaySchedules();
    loader.removeLoader('Loading Agent schedules...');
  }, []);

  const loadCustomers = async () => {
    setRouteCustomers(await getMyRouteCustomers(user.route));
  };

  useEffect(() => {
    if (user?.route) {
      setHasNoRouteAssigned(true);
      loader.addLoader('Loading Agent route...');
      loadCustomers();
      loader.removeLoader('Loading Agent route...');
    } else {
      setHasNoRouteAssigned(false);
    }
  }, [user]);

  useEffect(() => {
    const deliveries = [];
    todaySchedules?.forEach((schedule) => {
      if (user.route === schedule.customer.route) {
        deliveries.push({customer: schedule.customer,
          quantityInLiters: schedule.quantityInLiters,
          note: schedule?.note});
      }
    });
    routeCustomers?.forEach((customer) => {
      const schedule = todaySchedules?.find(({customer: cust}) =>
        cust.phoneNumber === customer.phoneNumber);
      if (!schedule) {
        deliveries.push({customer,
          quantityInLiters: customer.quantityInLiters});
      }
    });
    setTodayDeliveris(orderBy(
        deliveries.filter((delivery) =>
          !completedDeliveries.some((completed) => (
            completed.customer.phoneNumber === delivery.customer.phoneNumber
          ))),
        ({customer}) => customer.point));
  }, [routeCustomers, todaySchedules, completedDeliveries]);

  const moveToNextDelivery = () => {
    setCurrentCustomerIndex((index) => index + 1);
    handleCloseModal();
  };

  useEffect(() => {
    const fetchDeliveriesCount = async () =>
      setCurrentCustomerIndex(await getMyTodayDeliveriesCount());
    fetchDeliveriesCount();
  }, []);

  if (!hasRouteAssigned || todayDeliveries.length <= 0) {
    return (<>No Work assigned to you. Talk to your Manager!!!</>);
  }

  return (<>
    <TodaySchedule total={todayDeliveries.length}
      liters={sumBy(todayDeliveries, ({quantityInLiters}) => quantityInLiters)}
      completed={currentCustomerIndex}/>
    <Deliveries deliveries={todayDeliveries}
      completedDeliveries={completedDeliveries}
      currentDeliveryIndex={currentCustomerIndex}
      onCancel={showCancelModal} onDelivery={showDeliveryModal}/>
    <RemainingDeliveries
      remaining={todayDeliveries.length - (currentCustomerIndex + 1)}
      liters={sumBy(todayDeliveries, ({quantityInLiters}, index) =>
      index <= currentCustomerIndex ? 0 : quantityInLiters)}
      nextDelivery={todayDeliveries[currentCustomerIndex + 1]}/>
    <CancelDeliveryModal show={showCancel} onClose={handleCloseModal}
      onComplete={moveToNextDelivery}
      customer={currentDelivery?.customer}/>
    <MarkDeliveryModal show={showDelivery} onClose={handleCloseModal}
      onComplete={moveToNextDelivery}
      delivery={currentDelivery}/>
  </>);
};

export default DeliveryAgentHome;
