import { HourglassEmpty } from "@mui/icons-material";
import { CardContent, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import Search from "../../components/common/Search";
import ScheduleModal from "../../components/modals/ScheduleModal";
import { getNext100Schedules } from "../../data/schedules";
import { CardActionIcon, CardTitle, ContentTitle,
  StyledCard, StyledInfo, TruncatedName}
  from "../../home/CustomerHome/util";

function Schedules() {
  const [searchKey, setSearchKey] = useState('');
  const [schedules, setSchedules] = useState([]);
  const [filteredSchedules, setFilteredSchedules] = useState([]);
  const [scheduleCustomer, setScheduleCustomer] = useState();

  const loadSchedules = async () => setSchedules(await getNext100Schedules());
  useEffect(() => {
    loadSchedules();
  }, []);

  const onUpdatingSchedule = () => {
    setScheduleCustomer();
    loadSchedules();
  };

  useEffect(() => {
    if (searchKey) {
      setFilteredSchedules(schedules.filter(({customer, date}) => (
        customer.name.toLowerCase().includes(searchKey.toLowerCase()) ||
        moment(date.toDate()).format('MMM D, YYYY')
            .toLowerCase().includes(searchKey.toLowerCase()))));
    } else {
      setFilteredSchedules(schedules);
    }
  }, [searchKey, schedules]);

  return (<>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Schedules'}/>
      <Search placeholder={'Search'} onSearch={setSearchKey}/>
    </div>
    {filteredSchedules.map(({customer, ...schedule}) => (
      <StyledCard key={schedule.id}>
        <CardContent>
          <CardTitle>
            <TruncatedName>{customer.name}</TruncatedName>
            <small>{`${schedule.quantityInLiters} L`}</small>
          </CardTitle>
          <Typography variant="subtitle1" color="text.secondary"
            style={{display: 'flex', justifyContent: 'space-between'}}
            component="div">
            {`Scheduled: ${moment(schedule.date.toDate())
                .format('MMM D, YYYY')}`}
            <CardActionIcon aria-label="Schedule" Icon={HourglassEmpty}
              onClick={() => setScheduleCustomer(customer)}/>
          </Typography>
          { schedule?.note &&
            <Typography variant="body2">
              <StyledInfo fontSize='16'/> Note: {schedule?.note}
            </Typography>
          }
        </CardContent>
      </StyledCard>
    ))}
    <ScheduleModal show={!!scheduleCustomer} onClose={onUpdatingSchedule}
      name={scheduleCustomer?.name} phoneNumber={scheduleCustomer?.phoneNumber}
      liters={scheduleCustomer?.quantityInLiters} />
  </>);
};

export default Schedules;
