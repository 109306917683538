import { Typography } from "@mui/material";
import { ContentTitle } from "../../home/CustomerHome/util";

function AboutUs() {
  return (<>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'About Us'}/>
    </div><br/>
    <Typography variant="subtitle1" color="text.secondary">
      {`At HANI, we believe in the pure,
        unadulterated goodness of nature's bounty,
        brought straight from our idyllic dairy farm
        nestled in the heart of Channapatna.
        Founded with a passion for delivering the freshest and
        highest quality milk and Agro products to our customers,
        we are dedicated farmers committed to redefining
        the standards of dairy excellence.`}
    </Typography> <br/>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Our Story'}/>
    </div><br/>
    <Typography variant="subtitle1" color="text.secondary">
      {`Our journey began with a simple vision: to bridge the gap
        between urban consumers and the wholesome goodness of
        farm-fresh milk. With a deep-rooted respect for traditional
        farming practices and a commitment to sustainability, we set
        out to create a haven where happy cows roam lush pastures,
        producing milk that is as pure as the land from which it comes.`}
    </Typography> <br/>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Meet the Farmers'}/>
    </div><br/>
    <Typography variant="subtitle1" color="text.secondary">
      {`Behind every drop of our milk is a team of dedicated farmers
        who pour their hearts and souls into ensuring the health and
        happiness of our beloved bovines. From dawn till dusk, we work
        tirelessly to uphold the highest standards of animal welfare,
        environmental stewardship, and product quality, because we believe
        that the true measure of our success lies in the well-being of our
        cows and the satisfaction of our customers.`}
    </Typography> <br/>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Our Philosophy'}/>
    </div><br/>
    <Typography variant="subtitle1" color="text.secondary">
      {`At HANI, we adhere to a simple yet profound philosophy:
        to deliver not just milk, but a promise of freshness, purity,
        and nourishment with every sip. From our farm to your doorstep,
        we strive to uphold the values of integrity, transparency, and trust,
        because we understand that the food you consume is more than just
        sustenance-it's a reflection of the care and craftsmanship that
        goes into its creation.`}
    </Typography> <br/>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Sustainability & Community'}/>
    </div><br/>
    <Typography variant="subtitle1" color="text.secondary">
      {`Beyond providing wholesome dairy products, we are committed to
        fostering a sustainable future for generations to come. Through
        responsible land management, conservation practices, and community
        engagement initiatives, we aim to create a positive impact on the
        environment and the communities we serve.`}
    </Typography> <br/>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Join Us on Our Journey'}/>
    </div><br/>
    <Typography variant="subtitle1" color="text.secondary">
      {`We invite you to join us on our journey towards a healthier,
        happier world-one glass of milk at a time. Whether you're a loyal
        customer, a curious newcomer, or a fellow advocate for sustainable
        living, we welcome you to explore our farm, taste the difference of
        our fresh milk, and become a part of our growing family.`}
    </Typography> <br/>
  </>);
};

export default AboutUs;
