import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { createRoute, updateRoute } from "../../data/deliveryRouting";
import { fetchAllDeliveryAgents } from "../../data/user";
import ChooseAgent from "../common/ChooseAgent";
import CommonModal from "./CommonModal";
import { StyledTextField } from "./util";

function CreateRouteModal({show, onClose, onCreate, route}) {
  const [name, setName] = useState('');
  const [agent, setAgent] = useState('');
  const [deliveryAgents, setDeliveryAgents] = useState([]);

  const cleanCache = () => {
    setName('');
    setAgent('');
  };

  const handleName = (event) => {
    setName(event.target.value);
  };

  useEffect(() => {
    if (route) {
      setName(route.name);
      setAgent(route.agent.id);
    }
  }, [route]);

  const handleRouteCreation = async () => {
    if (route) {
      await updateRoute(route.id, name, agent);
    } else {
      await createRoute(name, agent);
    }
    cleanCache();
    onCreate();
  };

  const handleClose = () => {
    cleanCache();
    onClose();
  };

  const actions = [['Create', handleRouteCreation, !name || !agent],
    [`Cancel`, handleClose]];

  useEffect(() => {
    (async () => setDeliveryAgents(await fetchAllDeliveryAgents()))();
  }, []);

  return (
    <CommonModal show={show} onClose={handleClose} actions={actions}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Delivery Route
      </Typography>
      <StyledTextField value={name} variant="standard"
        onChange={handleName} label="Name" fullWidth/><br/><br/>

      <ChooseAgent agent={agent} onChange={setAgent}
        availableAgents={deliveryAgents}/><br/>
    </CommonModal>
  );
};

export default CreateRouteModal;
