import { Button, Typography } from "@mui/material";
import { ContentTitle } from "../../home/CustomerHome/util";

function ContactUs() {
  return (<>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Contact Us'}/>
    </div><br/>
    <Typography variant="subtitle1" color="text.secondary">
      {`We value your feedback, inquiries, and suggestions. Please feel
      free to get in touch with us using the following contact information.`}
    </Typography> <br/>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Address'}/>
    </div><br/>
    <Typography variant="subtitle1" color="text.secondary">
      Prayathna Farms,<br/>
      Siddegowdana Doddi,<br/>
      Channapatna, Karnataka 562138
    </Typography> <br/>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Phone'}/>
    </div><br/>
    <Typography variant="subtitle1" color="text.secondary">
      <Button sise="small" href="tel:9738270642">
        +91-9738270642</Button>
    </Typography> <br/>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Email'}/>
    </div><br/>
    <Typography variant="subtitle1" color="text.secondary">
      <Button sise="small" href="mailto:4hanimanager@gmail.com">
        4hanimanager@gmail.com</Button>
    </Typography> <br/>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Operating Hours'}/>
    </div><br/>
    <Typography variant="subtitle1" color="text.secondary">
      {`Everyday from 12:00 PM till 10:00 PM`}
    </Typography> <br/>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Connect With Us'}/>
    </div><br/>
    <Typography variant="subtitle1" color="text.secondary">
    Follow us on social media for updates, promotions, and more:<br/>
    - Facebook: [Link to Facebook Page]<br/>
    - Instagram: <Button size="small"
        href={`https://www.instagram.com/hani.4life`}>
          hani.4life</Button><br/>
    - Twitter: [Link to Twitter Profile]<br/>
    - LinkedIn: [Link to LinkedIn Page]
    </Typography> <br/>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Feedback Form'}/>
    </div><br/>
    <Typography variant="subtitle1" color="text.secondary">
      {`Have a question or comment? Fill out the form below,
        and we'll get back to you as soon as possible.`}
      &nbsp;&nbsp;<Button size="small">
        Feedback Form [Yet to create]</Button><br/>
      {`Thank you for choosing HANI for your daily needs. We
        look forward to serving you and providing you with the freshest,
        highest quality products straight from our farm to your table.`}
    </Typography> <br/>
  </>);
};

export default ContactUs;
