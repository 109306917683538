import React, {useEffect, useState} from 'react';
import {
  RouterProvider,
} from 'react-router-dom';
import {getAuth} from 'firebase/auth';
import {LoaderContext, UserContext, loaderContextIntialValue} from './contexts';
import {getRouter} from './routes';
import { IconButton, Snackbar, ThemeProvider } from '@mui/material';
import { addUser, getLoggedInUserInfo } from './data/user';
import { theme } from './theme';
import { remove } from 'lodash';
import { CloseOutlined } from '@mui/icons-material';
import Loader from './components/common/Loader';

const haniTool = {

};

window['hani_tool'] = haniTool;

function App() {
  const [userSession, setUserSession] = useState(null);
  const [user, setUser] = useState();
  const [loader, setLoader] = useState({isLoading: true});

  const [showError, setShowError] = useState(false);
  const [error, setError] = useState('');

  const clearUser = () => {
    setUser();
  };

  const addLoader = (item) => setLoader((previousLoader) => ({
    ...previousLoader,
    items: [...previousLoader?.items || [], item],
    isLoading: true,
  }));

  const removeLoader = (item) => setLoader((previousLoader) => {
    const newItems = [...previousLoader?.items || []];
    remove(newItems, (lItem) => lItem === item);
    return ({
      ...previousLoader,
      items: newItems,
      isLoading: newItems.length > 0,
    });
  });

  const handleError = (error) => {
    setError(error);
    if (error) {
      setShowError(true);
    }
  };

  const handleErrorClose = (_event, reason) => {
    if (reason !== 'clickaway') {
      setError('');
      setShowError(false);
    }
  };

  const changeRole = (role) => {
    setUser((user) => ({
      ...user,
      currentRole: role,
    }));
  };

  haniTool['addLoader'] = addLoader;
  haniTool['removeLoader'] = removeLoader;
  haniTool['onError'] = handleError;

  getAuth().onAuthStateChanged((session) => {
    if (session && session !== userSession) {
      setUserSession((prevSession) => {
        if (prevSession?.uid !== session?.uid) {
          return session;
        } else {
          return prevSession;
        }
      });
    }
  });

  useEffect(() => {
    if (userSession) {
      async function fetchUserInfo() {
        try {
          const userDoc = await getLoggedInUserInfo();
          const userInfo = userDoc.data();
          if (!userInfo.roles) {
            userInfo.roles = ['Customer'];
            userInfo.status = 'Enquired';
            userInfo.route = 'unknown';
          }
          setUser({...user, ...userInfo,
            currentRole: userInfo.roles[0],
            changeRole, clearUser,
          });
        } catch (e) {
          console.log('Error in App.js - ', e);
          await addUser({
            phoneNumber: userSession.phoneNumber,
          }, true);
          window.location.reload();
        }
      }
      fetchUserInfo();
    }
  }, [userSession]);

  useEffect(() => {
    setLoader({
      ...loaderContextIntialValue,
      addLoader,
      removeLoader,
      isLoading: false,
    });
  }, []);

  const router = getRouter();

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleErrorClose}
    >
      <CloseOutlined size="small"/>
    </IconButton>
  );

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <LoaderContext.Provider value={loader}>
          <UserContext.Provider value={user}>
            <RouterProvider router={router}
              fallbackElement={<Loader show />}/>
            <Snackbar open={showError}
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              autoHideDuration={3000}
              onClose={handleErrorClose}
              message={error} action={action}/>
          </UserContext.Provider>
        </LoaderContext.Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
