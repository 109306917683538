import styled from '@emotion/styled';
import {IconButton, useTheme} from '@mui/material';
import {useContext} from 'react';
import {UserContext} from '../contexts';
import {LogoutOutlined, RefreshOutlined} from '@mui/icons-material';
import {getAuth, signOut} from 'firebase/auth';
import {useNavigate} from 'react-router-dom';
import {spacing} from '../constant';
import HaniLogo from '../assets/images/hani_logo.png';

const StyledDiv = styled.div`
  height: ${spacing.header.height}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.header.paddingTop}px 
    16px ${spacing.header.paddingBottom}px 16px;
`;

const StyledLogoutOutlined = styled(LogoutOutlined)`
  cursor: pointer;
`;

const StyledRefreshOutlined = styled(RefreshOutlined)`
  cursor: pointer;
`;

const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLogo = styled.img`
  width: 50px;
`;

const StyledHANI = styled.span`
  font-weight: bolder;
  font-size: 28px;
  letter-spacing: 3px;
`;
function Header() {
  const theme = useTheme();
  const user = useContext(UserContext);
  const navigate = useNavigate();

  const reload = () => navigate(0);

  const logout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      user.clearUser();
      navigate('/login');
    } catch {
      console.log('Failed to logout');
    }
  };
  return (<>
    <StyledDiv theme={theme}>
      <StyledTitle>
        <StyledLogo src={HaniLogo}/>
        <StyledHANI>HANI</StyledHANI>
      </StyledTitle>
      <div>
        {user && <> <IconButton onClick={reload}>
          <StyledRefreshOutlined />
        </IconButton>
        <IconButton onClick={logout}>
          <StyledLogoutOutlined/>
        </IconButton> </>}
      </div>
    </StyledDiv>
    <hr/>
  </>);
};

export default Header;
