import { ContentTitle } from "../../home/CustomerHome/util";
import AddUpdateUser from "../AddUpdateUser";

function Profile() {
  return (<>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Profile'}/>
    </div>
    <AddUpdateUser isProfile/>
  </>);
};

export default Profile;
