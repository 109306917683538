import { collection, doc,
  orderBy, query, where,
} from "firebase/firestore";
import { db } from "../firebase";
import { getDocuments, getDocumentsWithCustomerInfo } from "./util";
import { getDocs, setDoc } from './';
import { v4 } from "uuid";
import { getCurrentUserRef, getUserRef } from "./user";

const collectionName = 'billing';

export const createBilling = async (customerId, details) => {
  window['hani_tool'].addLoader('Adding billing...');
  await setDoc(doc(db, collectionName, v4()), {
    customer: getUserRef(customerId),
    createdBy: getCurrentUserRef(),
    createdDate: new Date(),
    ...details,
  });
  window['hani_tool'].removeLoader('Adding billing...');
};

export const markPayment = async (billId, details) => {
  window['hani_tool'].addLoader('Adding paymnet...');
  await setDoc(doc(db, collectionName, billId), {
    modifiedBy: getCurrentUserRef(),
    modifiedDate: new Date(),
    ...details,
  }, {merge: true});
  window['hani_tool'].removeLoader('Adding paymnet...');
};

export const getCustomerBills = async (customerId) => {
  window['hani_tool'].addLoader('Fetching Customer Bills...');
  const qry = query(collection(db, collectionName),
      where('customer', '==', getUserRef(customerId)),
      orderBy('toDate', 'desc'));
  const docs = await getDocuments(await getDocs(qry));
  const result = await getDocumentsWithCustomerInfo(docs);
  window['hani_tool'].removeLoader('Fetching Customer Bills...');
  return result;
};
