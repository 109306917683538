import { collection, doc, limit,
  orderBy, query, setDoc, Timestamp,
  where } from "firebase/firestore";
import { db } from "../firebase";
import { v4 } from "uuid";
import { getCurrentUserRef, getUserRef } from "./user";
import moment from "moment";
import { getDocuments, getDocumentsWithCustomerInfo } from "./util";
import { first } from "lodash";
import { getDocs } from './';

const collectionName = 'schedules';

export const createSchedule = async (customerId, date,
    quantityInLiters, note='') => {
  window['hani_tool'].addLoader('create schedule...');
  let docId = v4();
  let createdBy = getCurrentUserRef();
  let createdDate = new Date();
  const modifiedBy = createdBy;
  const modifiedDate = createdDate;
  const qry = query(collection(db, collectionName), where("date", '==', date),
      where('customer', '==', getUserRef(customerId)));
  const documents = await getDocuments(await getDocs(qry));
  if (documents.length > 0) {
    const document = first(documents);
    docId = document.id;
    createdBy = document.createdBy;
    createdDate = document.createdDate;
  }

  await setDoc(doc(db, collectionName, docId), {
    customer: getUserRef(customerId),
    date,
    quantityInLiters,
    createdBy,
    createdDate,
    modifiedBy,
    modifiedDate,
    note,
  });
  window['hani_tool'].removeLoader('create schedule...');
};

export const getTodaySchedules = async () => {
  window['hani_tool'].addLoader('get today schedules...');
  const today = new Date(moment().format('yyyy-MM-DD'));
  const qry = query(collection(db, collectionName), where("date", '==', today),
      orderBy('customer'), orderBy('createdDate', 'desc'));
  const docs = getDocuments(await getDocs(qry));
  const result = await getDocumentsWithCustomerInfo(docs);
  window['hani_tool'].removeLoader('get today schedules...');
  return result;
};

export const getCustomerTodaySchedule = async (customerId = undefined) => {
  window['hani_tool'].addLoader('get customer today schedules...');
  const today = new Date(moment().format('yyyy-MM-DD'));
  const qry = query(collection(db, collectionName), where("date", '==', today),
      where("customer", '==', customerId ?
      getUserRef(customerId) : getCurrentUserRef(),
      orderBy('createdDate', 'desc'), limit(1)));
  const documents = await getDocuments(await getDocs(qry));
  let latest = first(documents);
  documents.forEach((doc) => {
    if (doc.createdDate.seconds > latest.createdDate.seconds) {
      latest = doc;
    }
  });
  window['hani_tool'].removeLoader('get customer today schedules...');
  return latest;
};

export const getNext15SchedulesOfCurrentCustomer = async () => {
  window['hani_tool'].addLoader('get customer nex 15 schedules...');
  const today = new Date(moment().format('yyyy-MM-DD'));
  const date = new Date(moment().add(15, 'days').format('yyyy-MM-DD'));
  const qry = query(collection(db, collectionName),
      where('customer', '==', getCurrentUserRef()),
      where('date', '<=', Timestamp.fromDate(date)),
      where('date', '>', Timestamp.fromDate(today)),
      orderBy('date', 'asc'));
  const documents = await getDocuments(await getDocs(qry));
  window['hani_tool'].removeLoader('get customer nex 15 schedules...');
  return documents;
};

export const getNext100Schedules = async () => {
  window['hani_tool'].addLoader('Fetching all schedules...');
  const today = new Date(moment().format('yyyy-MM-DD'));
  const qry = query(collection(db, collectionName),
      where('date', '>=', Timestamp.fromDate(today)),
      orderBy('date', 'asc'), limit(100));
  const docs = getDocuments(await getDocs(qry));
  const finalDocs = await getDocumentsWithCustomerInfo(docs);
  window['hani_tool'].removeLoader('Fetching all schedules...');
  return finalDocs;
};
