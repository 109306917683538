import { Edit, LocalPhone, Navigation,
  PersonAddAlt } from "@mui/icons-material";
import { CardContent, Fab, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAdduserNavigation } from "../../utils/nav";
import { useEffect, useState } from "react";
import { fetchALlStaff } from "../../data/user";
import { CardActionIcon, CardTitle, ContentTitle, ScreenAction, StyledCard,
  StyledCardActions } from "../../home/CustomerHome/util";
import { navigateToLocation } from "../../utils/common";

function Staffs() {
  const navigate = useNavigate();
  const [staffs, setStaffs] = useState([]);

  const addUser = () => navigate(getAdduserNavigation().path);

  useEffect(() => {
    async function fetchData() {
      setStaffs(await fetchALlStaff());
    };
    fetchData();
  }, []);

  return (<>
    <ContentTitle text={'Staffs'}/>
    {staffs.map((staff) => (
      <StyledCard key={staff.id}>
        <CardContent>
          <CardTitle>
            <span>{staff.name}</span>
            <small>{`Route 1`}</small>
          </CardTitle>
          <Typography variant="subtitle1" color="text.secondary"
            component="div">
            {staff?.roles?.length > 1 &&
              `${staff?.roles?.length} Roles`}
          </Typography>
          {staff.notes?.length > 0 && staff.notes.map((note, index) => (
            <Typography variant="body2" key={`${note.length}-${index}`}>
              {note}
            </Typography>
          ))}
        </CardContent>
        <StyledCardActions align="right">
          <CardActionIcon aria-label="Call Staff"
            href={`tel:${staff.phoneNumber}`} Icon={LocalPhone}/ >
          <CardActionIcon aria-label="Navigate" Icon={Navigation}
            onClick={() => navigateToLocation(staff.location)}/>
          <CardActionIcon aria-label="Edit Staff" Icon={Edit}
            onClick={() => navigate('/user',
                {state: staff, replace: true})}/>
        </StyledCardActions>
      </StyledCard>
    ))}
    <ScreenAction>
      <Fab color="primary" aria-label="add" onClick={addUser}>
        <PersonAddAlt />
      </Fab>
    </ScreenAction>
  </>);
};

export default Staffs;
