import { Button, Input, Typography } from "@mui/material";
import moment from 'moment';
import { useEffect, useState } from "react";
import { StyledTextField } from "../../components/modals/util";
import { createBilling } from "../../data/billing";

const costForHalfLtrMilk = 33;
const costForOneOrMoreLtrMilk = 65;

function BillingDetails({deliveries, customer, onBillToDateChange,
  clearCustomer}) {
  const [liters, setLiters] = useState(0);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [cost, setCost] = useState(0);
  const [discount, setDiscount] = useState(0);

  const handleFromDate = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDate = (event) => {
    setToDate(event.target.value);
    onBillToDateChange(new Date(event.target.value));
  };

  const handleDiscount = (e) => setDiscount(e.target.value);

  useEffect(() => {
    if (deliveries) {
      let totalMilk = 0;
      let milkCost = 0;
      let from = Date.now();
      let to = 0;
      deliveries.forEach((delivery) => {
        from = Math.min(from, delivery.date.seconds * 1000);
        to = Math.max(to, delivery.date.seconds * 1000);
        totalMilk += delivery.quantityInLiters;
        milkCost += delivery.quantityInLiters === 0.5 ?
          costForHalfLtrMilk :
          delivery.quantityInLiters * costForOneOrMoreLtrMilk;
      });
      setFromDate(moment(new Date(from)).format('YYYY-MM-DD'));
      !toDate && setToDate(moment(new Date(to)).format('YYYY-MM-DD'));
      setLiters(totalMilk);
      setCost(milkCost);
    }
  }, [deliveries]);

  const billCustomer = async () => {
    await createBilling(customer.id, {
      fromDate: new Date(fromDate),
      toDate: new Date(toDate),
      liters: liters,
      cost: cost,
      discount: discount,
      payable: cost - discount,
    });
    clearCustomer();
  };

  return deliveries?.length > 0 && (<>
    <Typography variant="subtitle1" color="text.secondary">
      <table><tbody>
        <tr>
          <td>Total deliveries</td>
          <td>:</td>
          <td>{deliveries.length}</td>
        </tr>
        <tr>
          <td>From Date</td>
          <td>:</td>
          <td>
            <StyledTextField value={fromDate} variant="standard"
              disabled onChange={handleFromDate}
              fullWidth type="date"/>
          </td>
        </tr>
        <tr>
          <td>To Date</td>
          <td>:</td>
          <td>
            <StyledTextField value={toDate} variant="standard"
              onChange={handleToDate}
              fullWidth type="date"/>
          </td>
        </tr>
        <tr>
          <td>Total milk delivered</td>
          <td>:</td>
          <td>{liters} liter(s)</td>
        </tr>
        <tr>
          <td>Total Cost</td>
          <td>:</td>
          <td>{cost} Rs</td>
        </tr>
        <tr>
          <td>Discount</td>
          <td>:</td>
          <td><Input placeholder={'000'} size={'small'} type={'number'}
            value={discount} onChange={handleDiscount}
            style={{
              width: 100,
              textAlign: 'center',
            }}/> Rs
          </td>
        </tr>
        <tr>
          <td>Total Payable</td>
          <td>:</td>
          <td>{cost-discount} Rs</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td><Button variant="contained" size="small"
            onClick={billCustomer}>
            Bill Customer</Button></td>
        </tr>
      </tbody></table>
    </Typography>
  </>);
};

export default BillingDetails;
