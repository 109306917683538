import { AddLocationAlt, ArrowForwardIos, Edit } from "@mui/icons-material";
import { CardContent, Fab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Search from "../../components/common/Search";
import CreateRouteModal from "../../components/modals/CreateRouteModal";
import { getAllRoutes } from "../../data/deliveryRouting";
import { CardActionIcon, CardTitle, ContentTitle,
  ScreenAction,
  StyledCard, TruncatedName, TruncatedText}
  from "../../home/CustomerHome/util";

function DeliveryRouting() {
  const navigate = useNavigate();

  const [searchKey, setSearchKey] = useState('');
  const [routes, setRoutes] = useState([]);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [showCreateRoute, setShowCreateRoute] = useState(false);
  const [currentRoute, setCurrentRoute] = useState();

  const editRoute = (route) => {
    setCurrentRoute(route);
    setShowCreateRoute(true);
  };

  const openCreateRoute = () => setShowCreateRoute(true);
  const closeCreateRoute = () => {
    setCurrentRoute();
    setShowCreateRoute(false);
  };

  const navigateToApplyRoute = ({id, name, customers}) => {
    navigate('/applyRoute', {
      state: {
        route: {id, name, customers},
      },
      replace: true,
    });
  };

  const loadRoutes= async () =>
    setRoutes(await getAllRoutes());
  useEffect(() => {
    loadRoutes();
  }, []);

  const handleNewRoute = () => {
    loadRoutes();
    setCurrentRoute();
    setShowCreateRoute(false);
  };

  useEffect(() => {
    if (searchKey) {
      setFilteredRoutes(routes.filter(({name}) => (
        name.toLowerCase().includes(searchKey.toLowerCase()))));
    } else {
      setFilteredRoutes(routes);
    }
  }, [searchKey, routes]);

  return (<>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Delivery Routing'}/>
      <Search placeholder={'Search'} onSearch={setSearchKey}/>
    </div>
    {filteredRoutes.map(({name, customers, id, agent}, index) => (
      <StyledCard key={id}>
        <CardContent>
          <CardTitle>
            <TruncatedName>{name}</TruncatedName>
            <small>{`${customers?.length || 0}`}</small>
          </CardTitle>
          <Typography variant="subtitle1" color="text.secondary"
            style={{display: 'flex', justifyContent: 'space-between'}}
            component="div">
            <TruncatedText maxWidth={'60%'}>
              {`Delivery Agent: ${agent.name}`}</TruncatedText>
            <div>
              <CardActionIcon aria-label="Update Route" Icon={Edit}
                onClick={() => editRoute(filteredRoutes[index])}/>
              <CardActionIcon aria-label="Add Customers"
                onClick={() => navigateToApplyRoute(filteredRoutes[index])}
                Icon={ArrowForwardIos}/>
            </div>
          </Typography>
        </CardContent>
      </StyledCard>
    ))}

    <ScreenAction>
      <Fab color="primary" aria-label="add" onClick={openCreateRoute}>
        <AddLocationAlt />
      </Fab>
    </ScreenAction>
    <CreateRouteModal show={showCreateRoute}
      onClose={closeCreateRoute}
      route={currentRoute}
      onCreate={handleNewRoute}/>
  </>);
};

export default DeliveryRouting;
