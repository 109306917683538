import { CurrencyRupee, Edit, HourglassEmpty, LocalPhone, Navigation,
  PersonAddAlt } from "@mui/icons-material";
import { CardContent, Fab, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAdduserNavigation } from "../../utils/nav";
import { useEffect, useState } from "react";
import { fetchALlCustomers } from "../../data/user";
import { CardActionIcon, CardTitle, ContentTitle, ScreenAction, StyledCard,
  StyledCardActions } from "../../home/CustomerHome/util";
import { navigateToLocation } from "../../utils/common";
import ScheduleModal from "../../components/modals/ScheduleModal";
import Search from "../../components/common/Search";

function Customers() {
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState('');
  const [customers, setCustomers] = useState([]);
  const [scheduleCustomer, setScheduleCustomer] = useState();

  const addUser = () => navigate(getAdduserNavigation().path);

  useEffect(() => {
    async function fetchData() {
      setCustomers(await fetchALlCustomers());
    };
    fetchData();
  }, []);

  return (<>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <ContentTitle text={'Customers'}/>
      <Search placeholder={'Search'} onSearch={setSearchKey}/>
    </div>
    {customers.filter(({name}) =>
      name?.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()))
        .map((customer) => (
          <StyledCard key={customer.id}>
            <CardContent style={{paddingBottom: 0}}>
              <CardTitle>
                <span>{customer.name}</span>
                <small>{`${customer.quantityInLiters} L`}</small>
              </CardTitle>
              <Typography variant="subtitle1" color="text.secondary"
                component="div">
                {customer.status}&emsp;
                {customer?.roles?.length > 1 &&
                  ` / Roles: ${customer?.roles?.length}`}
                {`Route/Point: ${customer.route}/${customer.point}`}
              </Typography>
              {customer.notes?.length > 0 && customer.notes
                  .map((note, index) => (
                    <Typography variant="body2"
                      color="text.secondary"
                      key={`${note.length}-${index}`}>
                      {note}
                    </Typography>
                  ))}
            </CardContent>
            <StyledCardActions align="right">
              <CardActionIcon aria-label="Bill Customer"
                Icon={CurrencyRupee}/ >
              <CardActionIcon aria-label="Call Customer"
                href={`tel:${customer.phoneNumber}`} Icon={LocalPhone}/ >
              { customer?.location?.length === 2 &&
                <CardActionIcon aria-label="Navigate"
                  onClick={() => navigateToLocation(customer.location)}
                  Icon={Navigation} /> }
              <CardActionIcon aria-label="Schedule" Icon={HourglassEmpty}
                onClick={() => setScheduleCustomer(customer)}/>
              <CardActionIcon aria-label="Edit Customer" Icon={Edit}
                onClick={() => navigate('/user',
                    {state: customer, replace: true})}/>
            </StyledCardActions>
          </StyledCard>
        ))}
    <ScheduleModal show={!!scheduleCustomer} onClose={setScheduleCustomer}
      name={scheduleCustomer?.name} phoneNumber={scheduleCustomer?.phoneNumber}
      liters={scheduleCustomer?.quantityInLiters} />
    <ScreenAction>
      <Fab color="primary" aria-label="add" onClick={addUser}>
        <PersonAddAlt />
      </Fab>
    </ScreenAction>
  </>);
};

export default Customers;
