import { Typography } from "@mui/material";
import { contactNumber } from "../constant";

function UnavailableService({service}) {
  return (
    <Typography variant="subtitle1" color="text.secondary"
      component="div">
      {`Sorry for the inconvenience. Currently ${service} service is
          not available for you.
          For any further queries please call us on `}
      <a href={`tel:${contactNumber}`}>
        {contactNumber}.
      </a>
    </Typography>
  );
};

export default UnavailableService;
