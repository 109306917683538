import { TextField, Typography } from "@mui/material";
import CommonModal from "./CommonModal";
import { useContext, useEffect, useState } from "react";
import { createDelivery } from "../../data/deliveries";
import { disableValueScrollOnNumberFeild } from "../../home/CustomerHome/util";
import { StyledTextField } from "./util";
import { LoaderContext } from "../../contexts";

function MarkDeliveryModal({show, onClose, delivery, onComplete}) {
  const {customer} = delivery || {};
  const [note, setNote] = useState();
  const [quantityInLiters, setQuantityInLiters] = useState();
  const loader = useContext(LoaderContext);

  const handeleNote = (event) => setNote(event.target.value);
  const handeleQuantity = (event) => setQuantityInLiters(event.target.value);

  useEffect(() => {
    setQuantityInLiters(delivery?.quantityInLiters || 0);
  }, [delivery]);

  const onDelivery = async () => {
    loader.addLoader('Mark Delivery...');
    await createDelivery(customer.phoneNumber, quantityInLiters, note);
    loader.removeLoader('Mark Delivery...');
    setNote();
    onComplete();
  };

  const actions = [[`Delivered`, onDelivery],
    ['Close', onClose]];
  return (
    <CommonModal show={show} onClose={onClose} actions={actions}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Mark Delivery
      </Typography>
      <StyledTextField value={customer?.name} disabled variant="standard"
        label="Name" fullWidth/><br/>
      <StyledTextField required value={quantityInLiters} variant="standard"
        type="number" onWheel={disableValueScrollOnNumberFeild}
        onChange={handeleQuantity} label="Quantity In Liters" fullWidth/><br/>
      <TextField value={note} variant="standard" multiline fullWidth
        onChange={handeleNote} label="Delivery Note"/><br/>
    </CommonModal>
  );
};

export default MarkDeliveryModal;
