import {createBrowserRouter} from 'react-router-dom';
import Info from './info';
import Login from './auth/login';
import Content from './components/Content';
import { getAllRoutes } from './utils/nav';

export const getRouter = () => createBrowserRouter([
  {
    path: '/',
    element: <Info/>,
  }, {
    path: '/login',
    element: <Content><Login/></Content>,
  },
  ...getAllRoutes(),
]);
