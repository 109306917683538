import { Button, CardContent, Typography } from '@mui/material';
import { StyledCard as Card, CardActionIcon,
  StyledCardActions as CardActions,
  StyledInfo} from '../CustomerHome/util';
import { Clear, DeliveryDining, LocalPhone,
  Navigation } from '@mui/icons-material';
import {navigateToLocation} from '../../utils/common';

function Delivery({ delivery, onCancel, onDelivery, isDelivered }) {
  const getCurrentDelivery = ({customer, quantityInLiters, note} =
  {customer: {}, quantityInLiters: 0, note: ''}) => {
    const milkInLiters = quantityInLiters || 0;
    const bottles = milkInLiters * 2;
    const deliveryElm = (<Card>
      <CardContent style={{paddingBottom: 0}}>
        <Typography variant="subtitle1" color="text.secondary"
          component="div">
          { `${customer?.name} / ${bottles} bottles`}
        </Typography>
        <Typography variant="body2">
          {`HANI Milk: ${milkInLiters} liter(s) / ${customer?.route}`}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {_.first(customer?.notes) || ''}
        </Typography>
        { note &&
          <Typography variant="body2">
            <StyledInfo fontSize='16'/> Note: {note}
          </Typography>
        }
      </CardContent>
      {!isDelivered && <CardActions align="right">
        <CardActionIcon aria-label="Call Customer" Icon={LocalPhone}
          href={`tel:${customer?.phoneNumber}`}/>
        <CardActionIcon aria-label="Navigate" Icon={Navigation}
          onClick={() => navigateToLocation(customer?.location)}/>
        <CardActionIcon aria-label="Cancel"
          onClick={() => onCancel(delivery)} Icon={Clear} />
        <CardActionIcon aria-label="Deliver"
          onClick={() => onDelivery(delivery)}
          Icon={DeliveryDining} />
      </CardActions>}
      {isDelivered && <CardActions align="right">
        <Button size="small" disabled>
          {quantityInLiters > 0 ? 'Delivered' : 'Cancelled'}
        </Button>
      </CardActions>}
    </Card>);
    return deliveryElm;
  };

  return (<>
    {delivery && getCurrentDelivery(delivery)}
  </>);
};

export default Delivery;
