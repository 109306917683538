import { useEffect, useState } from "react";
import { StyledH4 } from "../../home/CustomerHome/util";
import { Button, IconButton, List, ListItem, ListItemText,
  TextField } from "@mui/material";
import styled from "@emotion/styled";
import { Delete } from "@mui/icons-material";
import { remove } from "lodash";

const StyledButton = styled(Button)`
  margin: 8px 0;
`;

const StyledListItem = styled(ListItem)`
  padding: 0;
`;

function Notes({values, onChange, label}) {
  const [newValues, setNewValues] = useState([]);
  const [value, setValue] = useState('');

  const handleNewValue = (event) => setValue(event.target.value);

  const addValue = () => {
    if (value) {
      const updatedValues = [...newValues, value];
      setNewValues(updatedValues);
      onChange([...updatedValues]);
      setValue('');
    }
  };

  const removeValue = (index) => {
    const afterRemoval = remove(newValues,
        (_value, valueIndex) => index !== valueIndex);
    setNewValues(afterRemoval);
    onChange(afterRemoval);
  };

  useEffect(() => {
    setNewValues(values || []);
  }, [values]);

  return (
    <>
      <StyledH4 text={label}/>
      {newValues.length > 0 && <List>
        {newValues.map((text, index) => (
          <StyledListItem key={`${text}-${index}`} secondaryAction={
            <IconButton edge="end" aria-label="delete"
              onClick={() => removeValue(index)}>
              <Delete />
            </IconButton>
          }>
            <ListItemText
              primary={text}/>
          </StyledListItem>))}
      </List>}
      <TextField label={`New ${label}`} value={value}
        onChange={handleNewValue} variant="standard"/>
      <StyledButton onClick={addValue} variant="outlined" size="small">
        {`Add ${label}`}</StyledButton>
    </>);
};

export default Notes;
