import {contactNumber} from './constant';
import {device} from './utils/device';

const getWhatsAppEnquiry = (msg, to) => {
  const ping = msg || 'Hello Team HANI, '+
    'I\'m curious about Hani farm-fresh milk. Can you share details, pricing, '+
    'and delivery availability in my area? Also, do you offer subscriptions?';
  return formWhatsAppMessage(ping, contactNumber);
};

const formWhatsAppMessage = (msg, to) => {
  let message = '';

  if (device.device.type === 'desktop') {
    message += 'https://web.whatsapp.com/send?text=';
  } else {
    message += 'whatsapp://send?text=';
  }
  message += `${msg}&phone=${to}&abid=${to}`;

  return message;
};

export {
  getWhatsAppEnquiry,
  formWhatsAppMessage,
};
