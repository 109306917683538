const contactNumber = '+916362908055';
const spacing = {
  header: {
    height: 30,
    paddingTop: 16,
    paddingBottom: 8,
    separatorHeight: 18,
  },
};
export {
  contactNumber,
  spacing,
};
