import { Button, Card, CardActions, CardContent,
  CardMedia, Typography } from "@mui/material";

import _ from 'lodash';
import productImage from '../assets/images/products/product.jpeg';

function Product({product, onAction}) {
  const {name, desc, orderDesc, actions=['Interested']} = product;
  let image = productImage;
  try {
    image = require(`../assets/images/products/${name}.jpeg`);
  } catch {
    console.log('Missing product image - ', name);
  }
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        alt={name}
        height="140"
        image={image}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {_.capitalize(name)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {desc}
        </Typography>
        {orderDesc && <>
          <hr/>
          <Typography variant="body2" color="text.secondary">
            {orderDesc}
          </Typography>
        </>}
      </CardContent>
      <CardActions>
        {actions.map((action) => (
          <Button size="small" key={action}
            onClick={() => onAction(action, product)}>{action}</Button>
        ))}
      </CardActions>
    </Card>);
};

export default Product;
