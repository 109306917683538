import {Button, TextField} from '@mui/material';
import {getAuth} from 'firebase/auth';
import {sendLoginOTP, setRecaptchaVerifier, verifyOTP} from './util';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {LoaderContext, UserContext} from '../contexts';
import styled from '@emotion/styled';
import PhoneNumber from '../components/common/PhoneNumber';
const auth = getAuth();
auth.useDeviceLanguage();

const enterPhone = 'enterPhone';
const enterOTP = 'enterOTP';

const BodyDiv = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;

function Login() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [step, setStep] = useState(enterPhone);

  const [phoneNumberOnFocus, setPhoneNumberOnFocus] =useState(false);
  const [otpOnFocus, setOtpOnFocus] =useState(false);

  const user = useContext(UserContext);
  const loader = useContext(LoaderContext);
  const navigate = useNavigate();

  /*
  const handlePhoneNumberChange = (event) => {
    const number = event.target.value;
    if (!isNaN(number) && number.length <= 10) {
      setPhoneNumber(number);
    }
  }; */

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const generateOTP = async () => {
    await sendLoginOTP(phoneNumber);
    setStep(enterOTP);
  };

  const validateOTP = async () => {
    loader.addLoader('Verify OTP...');
    await verifyOTP(code);
    loader.removeLoader('Verify OTP...');
    setTimeout(() => navigate('/home'), 2000);
  };

  useEffect(() => {
    setRecaptchaVerifier();
  }, []);

  useEffect(() => {
    if (user) {
      navigate('/home');
    }
  }, [user]);

  if (user) {
    return null;
  }

  const getPhLabelWidth = () => phoneNumberOnFocus || phoneNumber.length >= 3 ?
    undefined : 'inherit';

  const getOtpLabelWidth = () => otpOnFocus || code.length > 0 ?
    undefined : 'inherit';

  return (<BodyDiv>
    <h1>Login</h1>
    <PhoneNumber onChange={setPhoneNumber} fullWidth
      onFocus={setPhoneNumberOnFocus}
      onBlur={() => setPhoneNumberOnFocus(false)}
      sx={{input: {textAlign: 'center'}, label: {textAlign: 'center',
        width: getPhLabelWidth()}}}/>
    <br/>
    {step !== enterOTP && <div id='recaptcha-container'></div>}
    <br/>
    <TextField id='login-otp' label="One Time Password"
      variant='standard' fullWidth
      disabled={step !== enterOTP}
      onFocus={setOtpOnFocus}
      onBlur={() => setOtpOnFocus(false)}
      sx={{input: {textAlign: 'center'}, label: {textAlign: 'center',
        width: getOtpLabelWidth()}}}
      value={code} placeholder='Enter OTP'
      onChange={handleCodeChange}/>
    <br/><br/>
    { step === enterPhone &&
    <Button id="sign-in-button" disabled={phoneNumber.length < 10}
      onClick={generateOTP} variant="contained">Generate OTP</Button> }
    {step === enterOTP && <Button onClick={validateOTP}
      variant="contained">Sign In</Button>}
  </BodyDiv>);
};

export default Login;
