import {RecaptchaVerifier, getAuth, signInWithPhoneNumber} from 'firebase/auth';

const auth = getAuth();
auth.useDeviceLanguage();


const setRecaptchaVerifier = () => {
  try {
    window.recaptchaVerifier =
      new RecaptchaVerifier(auth, 'recaptcha-container', {});
  } catch (error) {
    console.log(error);
  } finally {
    window.hani_tool.removeLoader('recaptcha verifier ...');
  }
};

const sendLoginOTP = (phoneNumber) => {
  window.hani_tool.addLoader('recaptcha verifier ...');
  setTimeout(() => window.hani_tool.removeLoader('recaptcha verifier ...'),
      3000);
  const appVerifier = window.recaptchaVerifier;
  return signInWithPhoneNumber(auth, `${phoneNumber}`, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the
        // code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      }).catch((error) => {
        // Error; SMS not sent
        // ...
      });
};

const verifyOTP = (code) => {
  window.hani_tool.addLoader('Verify OTP ...');
  window.confirmationResult.confirm(code).then((result) => {
    // User signed in successfully.
    const user = result.user;
    console.log(user);
    window.location.assign('/home');
    // ...
  }).catch((error) => {
    // User couldn't sign in (bad verification code?)
    // ...
  }).finally(() => {
    window.hani_tool.removeLoader('recaptcha verifier ...');
  });
};

export {
  sendLoginOTP,
  verifyOTP,
  setRecaptchaVerifier,
};

