import { contactNumber } from "../../constant";

export const generateBillMessage = ({
  customerName,
  fromDate,
  toDate,
  payable,
  items,
}) => {
  let message = `
  *Bill Statement*%0A

  *Customer Name:* ${customerName}%0A
  *Delivery Address:* RR Nagar, Bengaluru.%0A
  *Delivery Period:* ${fromDate} to ${toDate}%0A

  %0A%0A

  *Date* | *Quantity* | *Rate/ltr* | *Amount*%0A`;

  const paymentMessage =
    `*Click here to make the payment:* upi://pay?pa=4hani@sbi.`;
    // &pn=HANI&am=${payable}&cu=INR

  items.forEach((item) => {
    message += `
  ${item.date} |
   ${item.quantity} ltrs |
   ${item.rate} Rs |
   ${item.amount} Rs%0A`;
  });

  message += `
  %0A%0A

  *Total Amount Due: Rs.${payable}*%0A%0A

  For more details and to view your complete statement,
  click [here](https://4hani.in/invoice).%0A%0A

  Please arrange the payment at your earliest convenience.
  If you have any questions or concerns,
  feel free to contact us at +${contactNumber}.%0A%0A

  ${paymentMessage}%0A%0A

  You can conveniently make your payment through UPI ID *4hani@sbi*.%0A%0A

  Thank you for your business!!!%0A%0A`;

  return message;
};
