import { Typography } from "@mui/material";
import moment from "moment";
import styled from '@emotion/styled';
import { useCallback, useContext, useEffect, useState } from "react";
import { fetchBillDeliveries } from "../../data/deliveries";
import CommonModal from "./CommonModal";
import { StyledTextField } from "./util";
import { UserContext } from "../../contexts";
import { formWhatsAppMessage } from "../../messages";
import { generateBillMessage } from "../../data/billMessage";

const StyledTableWrapper = styled.div`
  max-height: 250px;
  overflow: auto;
`;

function ViewBillModal({show, onClose, bill}) {
  const user = useContext(UserContext);
  const {customer} = bill || {};
  const [billDeliveries, setBillDeliveries] = useState([]);
  const [actions, setActions] = useState([]);

  const notifyCustomer = useCallback(() => {
    if (!customer ||!user || !billDeliveries) {
      return true;
    }
    const items = billDeliveries.map((del) => ({
      date: moment(del.date.seconds * 1000).format('DD-MMM-YYYY'),
      quantity: del.quantityInLiters,
      rate: del.quantityInLiters < 0.5 ?
        0 : del.quantityInLiters === 0.5 ? 33 : 65,
      amount: (del.quantityInLiters === 0.5 ? 33 : (65 *
        del.quantityInLiters)),
    }));
    window.open(formWhatsAppMessage(generateBillMessage({
      customerName: customer.name,
      fromDate: moment(bill.fromDate.seconds * 1000).format('DD-MMM-YYYY'),
      toDate: moment(bill.toDate.seconds * 1000).format('DD-MMM-YYYY'),
      payable: bill.payable,
      items,
    }), customer.phoneNumber));
  }, [customer, bill, billDeliveries]);

  const makePayment = () => {
    window.open(`upi://pay?pa=4hani@sbi&pn=HANI&am=${bill?.payable || 0}&cu=INR`);
  };

  useEffect(() => {
    const a = [];
    if (user && user.currentRole != 'Customer') {
      a.push(['Notify Customer', notifyCustomer]);
    } else if (user.currentRole === 'Customer' &&
      bill?.status !== 'PAID') {
      a.push(['Make Payment', makePayment]);
    } else {
      a.push(['Close', onClose]);
    }
    setActions(a);
  }, [user, customer, bill, billDeliveries]);

  useEffect(() => {
    if (bill) {
      const f = async () => {
        const delevires = await fetchBillDeliveries(bill);

        setBillDeliveries(delevires.sort((d1, d2) => (
          d1.date.seconds - d2.date.seconds
        )));
      };
      f();
    }
  }, [bill]);

  return (
    <CommonModal show={show} onClose={onClose} actions={actions}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Bill Details
      </Typography>
      <StyledTextField value={customer?.name} disabled variant="standard"
        label="Name" fullWidth/><br/>
      <StyledTextField value={bill?.payable} disabled variant="standard"
        label="Payable Amount" fullWidth/><br/>
      <StyledTextField
        value={bill?.status === 'PAID'? 'PAID' : 'PENDING / VERIFYING'}
        disabled variant="standard"
        label="Payment Status" fullWidth/><br/>
      <StyledTableWrapper><table style={{
        margin: 'auto',
      }}>
        <tbody>
          <tr>
            <td>Date</td>
            <td>| Quantity</td>
            <td>| Rate/Ltr</td>
            <td>| Amount</td>
          </tr>
          {billDeliveries?.map((del) => (
            <tr key={del.id}>
              <td>{moment(del.date.seconds * 1000).format('DD-MMM-YYYY')}</td>
              <td>| {del.quantityInLiters || 0} ltrs</td>
              <td>| {del.quantityInLiters < 0.5 ? 0 :
                      del.quantityInLiters === 0.5 ? 33 : 65} Rs</td>
              <td>| {
                (del.quantityInLiters === 0.5 ? 33 :
                  (65 * del.quantityInLiters))} Rs
              </td>
            </tr>
          ))}
        </tbody>
      </table></StyledTableWrapper>
    </CommonModal>
  );
};

export default ViewBillModal;
