import TodaySchedule from '../DeliveryAgentHome/TodaySchedule';
import { useEffect, useState } from 'react';
import { fetchALlCustomers } from '../../data/user';
import { getTodaySchedules } from '../../data/schedules';
import { orderBy, sumBy } from 'lodash';
import CancelDeliveryModal from '../../components/modals/CancelDeliveryModal';
import MarkDeliveryModal from '../../components/modals/MarkDeliveryModal';
import { getMyTodayDeliveries, getMyTodayDeliveriesCount }
  from '../../data/deliveries';
import Deliveries from '../DeliveryAgentHome/Deliveries';

function ManagerHome() {
  const [routeCustomers, setRouteCustomers] = useState([]);
  const [todaySchedules, setTodaySchedules] = useState([]);
  const [currentCustomerIndex, setCurrentCustomerIndex] = useState(0);
  const [todayDeliveries, setTodayDeliveris] = useState([]);
  const [currentDelivery, setCurrentDelivery] = useState();

  const [completedDeliveries, setCompletedDeliveries] = useState([]);

  const [showCancel, setShowCancel] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  const showCancelModal = (delivery) => {
    setCurrentDelivery(delivery);
    setShowCancel(true);
  };
  const showDeliveryModal = (delivery) => {
    setCurrentDelivery(delivery);
    setShowDelivery(true);
  };

  const handleCloseModal = async () => {
    setShowCancel(false);
    setShowDelivery(false);
    setCurrentDelivery();
    setCompletedDeliveries(await getMyTodayDeliveries());
  };

  const loadCustomers = async () => {
    setRouteCustomers(await fetchALlCustomers());
  };

  useEffect(() => {
    const fethTodaySchedules = async () => {
      const schedules = await getTodaySchedules();
      setTodaySchedules(schedules);
      setCompletedDeliveries(await getMyTodayDeliveries(false));
    };
    fethTodaySchedules();
    loadCustomers();
  }, []);

  useEffect(() => {
    const deliveries = [];
    todaySchedules?.forEach((schedule) => {
      deliveries.push({customer: schedule.customer,
        quantityInLiters: schedule.quantityInLiters,
        note: schedule?.note});
    });
    routeCustomers?.forEach((customer) => {
      const schedule = todaySchedules?.find(({customer: cust}) =>
        cust.phoneNumber === customer.phoneNumber);
      if (!schedule && customer.status === 'Subscribed') {
        deliveries.push({customer,
          quantityInLiters: customer.quantityInLiters});
      }
    });
    setTodayDeliveris(orderBy(
        deliveries.filter((delivery) =>
          !completedDeliveries.some((completed) => (
            completed.customer.phoneNumber === delivery.customer.phoneNumber
          ))),
        ({customer}) => customer.point));
  }, [routeCustomers, todaySchedules, completedDeliveries]);

  const moveToNextDelivery = () => {
    setCurrentCustomerIndex((index) => index + 1);
    handleCloseModal();
  };

  useEffect(() => {
    const fetchDeliveriesCount = async () =>
      setCurrentCustomerIndex(await getMyTodayDeliveriesCount());
    fetchDeliveriesCount();
  }, []);

  return (<>
    <TodaySchedule total={todayDeliveries.length}
      liters={sumBy(todayDeliveries, ({quantityInLiters}) => quantityInLiters)}
      completed={currentCustomerIndex}/>
    <Deliveries deliveries={todayDeliveries}
      completedDeliveries={completedDeliveries}
      currentDeliveryIndex={currentCustomerIndex}
      onCancel={showCancelModal} onDelivery={showDeliveryModal}/>
    <CancelDeliveryModal show={showCancel} onClose={handleCloseModal}
      onComplete={moveToNextDelivery}
      customer={currentDelivery?.customer}/>
    <MarkDeliveryModal show={showDelivery} onClose={handleCloseModal}
      onComplete={moveToNextDelivery}
      delivery={currentDelivery}/>
  </>);
};

export default ManagerHome;
