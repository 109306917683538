import TodaySchedule from './TodaySchedule';
import PreviousDeliveries from './PreviousDeliveries';
import RemainingDeliveries from './UpcomingSchedules';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts';
import ScheduleModal from '../../components/modals/ScheduleModal';
import CancelScheduleModal from '../../components/modals/CancelScheduleModal';
import moment from 'moment';
import RateDeliveryModal from '../../components/modals/RateDeliveryModal';
import { getLast15DeliveriesOfCurrentCustomer } from '../../data/deliveries';
import { getCustomerTodaySchedule, getNext15SchedulesOfCurrentCustomer }
  from '../../data/schedules';
import { first } from 'lodash';
import { fetchData } from '../../data/util';
import { getRouteAgent } from '../../data/user';
import EnquiredCustomer from './EnquiredCustomer';
import DiscontinuedCustomer from './DiscontinuedCustomer';

function CustomerHome() {
  const user = useContext(UserContext);

  const [showModify, setShowModify] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showRating, setShowRating] = useState(false);

  const [ratingDelivery, setRatingDelivery] = useState();
  const [editDate, setEditDate] = useState();
  const [editQuantity, setEditQuantity] = useState();
  const [todaySchedule, setTodaySchedule] = useState();
  const [lastDeliveries, setLastDeliveries] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [agent, setAgent] = useState();

  useEffect(() => {
    if (user) {
      fetchData(setAgent, getRouteAgent, [user.route]);
      fetchData(setTodaySchedule, getCustomerTodaySchedule, [user?.id]);
      fetchData(setLastDeliveries, getLast15DeliveriesOfCurrentCustomer, []);
      fetchData(setSchedules, getNext15SchedulesOfCurrentCustomer, []);
    }
  }, [user]);


  const invokeModifyModal = (date, quantity) => {
    setShowModify(true);
    setEditDate(moment(date).format('yyyy-MM-DD'));
    setEditQuantity(quantity);
  };

  const invokeRatingModal = (delivery) => {
    setShowRating(true);
    setRatingDelivery(delivery);
  };

  const invokeCancelModal = (date) => {
    setShowCancel(true);
    setEditDate(moment(date).format('yyyy-MM-DD'));
    setEditQuantity();
  };

  const onModalClose = () => {
    setShowModify(false);
    setShowCancel(false);
    setShowRating(false);
    setRatingDelivery();
    setEditDate();
    setEditQuantity();
  };

  const hasDelivered = () => moment().format('yyyy-MM-DD') ===
    moment(first(lastDeliveries)?.date?.seconds * 1000).format('yyyy-MM-DD');

  const onUpdatingLastDeliveries = () => {
    fetchData(setLastDeliveries, getLast15DeliveriesOfCurrentCustomer, []);
    onModalClose();
  };

  const onUpdatingNextSchedules = () => {
    fetchData(setTodaySchedule, getCustomerTodaySchedule, [user?.id]);
    fetchData(setSchedules, getNext15SchedulesOfCurrentCustomer, []);
    onModalClose();
  };

  if (user.status === 'Enquired') {
    return <EnquiredCustomer />;
  }

  if (user.status === 'Discontinued') {
    return <DiscontinuedCustomer />;
  }

  return (<>
    <TodaySchedule onCancel={invokeCancelModal} onModify={invokeModifyModal}
      delivered={hasDelivered()} agent={agent} schedule={todaySchedule}/>
    { user.status === 'Subscribed' &&
      <RemainingDeliveries onCancel={invokeCancelModal}
        onModify={invokeModifyModal} agent={agent}
        schedules={schedules}/>}
    <PreviousDeliveries onRateDelivery={invokeRatingModal}
      lastDeliveries={lastDeliveries}/>
    <ScheduleModal show={showModify} name={user?.name}
      phoneNumber={user.phoneNumber} onComplete={onUpdatingNextSchedules}
      onClose={onModalClose} scheduledDate={editDate} liters={editQuantity}/>
    <CancelScheduleModal show={showCancel} onClose={onModalClose}
      scheduledDate={editDate} onComplete={onUpdatingNextSchedules}/>
    <RateDeliveryModal show={showRating} onClose={onModalClose}
      delivery={ratingDelivery} onComplete={onUpdatingLastDeliveries}/>
  </>);
};

export default CustomerHome;
