import { doc, setDoc } from "@firebase/firestore";
import { v4 } from "uuid";
import { db } from "../firebase";
import { getProductRef } from "./products";
import { getCurrentUserRef } from "./user";

const collectionName = 'interestedProducts';

export const createInterestedProduct = async (product) => {
  window['hani_tool'].addLoader('Creating product interest...');
  await setDoc(doc(db, collectionName, v4()), {
    customer: getCurrentUserRef(),
    product: getProductRef(product.id),
    createdBy: getCurrentUserRef(),
    createdDate: new Date(),
  });
  window['hani_tool'].removeLoader('Creating product interest...');
};
