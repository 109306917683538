import { Checkbox, FormControl, InputLabel, ListItemText,
  MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";

const availableRoles = ['Customer', 'Manager', 'Delivery Agent'];
const valuesRenderer = (values) => values.join(',');

function UserRole({roles, onChange}) {
  const [newRoles, setNewRoles] = useState([]);
  const handleRoles = (event) => {
    setNewRoles(event.target.value);
    onChange(event.target.value);
  };

  useEffect(() => {
    setNewRoles(roles);
  }, [roles]);

  return (<FormControl fullWidth margin="0">
    <InputLabel id="roles-label-id" style={{left: '-14px'}}>
      User Role(s)</InputLabel>
    <Select multiple required variant="standard"
      labelId="roles-label-id"
      value={newRoles} onChange={handleRoles}
      renderValue={valuesRenderer}>
      {availableRoles.map((availableRole) => (
        <MenuItem key={availableRole} value={availableRole}>
          <Checkbox checked={newRoles.includes(availableRole)}/>
          <ListItemText primary={availableRole}/>
        </MenuItem>
      ))}
    </Select>
  </FormControl>);
};

export default UserRole;
