import {createContext} from 'react';

export const loaderContextIntialValue = {
  items: [],
  isLoading: false,
  addLoader: (item) => {},
  removeLoader: (item) => {},
};

export const LoaderContext = createContext({});
