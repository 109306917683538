import { CardContent, Typography } from '@mui/material';
import { StyledCard as Card,
  DeliveriesContainer, StyledH4 } from '../CustomerHome/util';

function RemainingDeliveries({nextDelivery, remaining, liters}) {
  const getRemainingSchedules = () => {
    const { customer } = nextDelivery;
    const schedules = (<Card>
      <CardContent>
        <Typography variant="subtitle1" color="text.secondary"
          component="div">
          {'Next: '}
          {`${customer.name} / 
          ${nextDelivery?.quantityInLiters * 2} 
          bottles`}
        </Typography>
        <Typography variant="body2">
          {`Remaining: ${remaining} Deliveries,`}
          &emsp;
          {`${liters * 2} Bottles`}
        </Typography>
      </CardContent>
    </Card>);
    return schedules;
  };

  if (!nextDelivery) {
    return null;
  }

  return (<DeliveriesContainer>
    <StyledH4 text={'Remaining Deliveries'}/>
    {nextDelivery && getRemainingSchedules()}
  </DeliveriesContainer>);
};

export default RemainingDeliveries;
