import logo from '../assets/images/hani.jpeg';
import styled from 'styled-components';
import {getWhatsAppEnquiry} from '../messages';
import { UserContext } from '../contexts';
import { useContext, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100vh;
  overflow: hidden;
`;
const StyledBannerImage = styled.img`
  width: 100vw;
  max-height: 100vh;
`;

const ButtonStyle = {
  position: 'absolute',
  right: 0,
  top: 0,
  margin: '10px',
};

const signInOrSignUp = 'Sign In / Sing Up';
const goToHome = 'Go to Home';

function Info() {
  const user = useContext(UserContext);
  const [goIn, setGoIn] = useState(signInOrSignUp);
  const navigate = useNavigate();

  const goHome = () => navigate('/home');

  useEffect(() => {
    if (user) {
      setGoIn(goToHome);
    } else {
      setGoIn(signInOrSignUp);
    }
  }, [user]);

  return (
    <StyledInfo>
      <Button style={ButtonStyle} onClick={goHome}>{goIn}</Button>
      <a href={getWhatsAppEnquiry()} target="_blank" rel="noreferrer" >
        <StyledBannerImage src={logo} alt="logo" />
      </a>
    </StyledInfo>
  );
}

export default Info;
