import { Button, CardContent, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts';
import { getActualQuantityInLiters } from '../../data/util';
import { StyledCardActions, StyledCard, StyledDeliveryDining,
  StyledSchedule,
  isLateToUpdateQuantityForToday,
  StyledInfo} from './util';

function TodaySchedule({onCancel, onModify, agent, delivered, schedule}) {
  const user = useContext(UserContext);
  const [liters, setLiters] = useState(0);

  useEffect(() => {
    setLiters(getActualQuantityInLiters(schedule, user));
  }, [schedule]);

  const handleCancel = () => onCancel(new Date());
  const handleModify = () => onModify(new Date(), liters);
  return (
    <StyledCard>
      <CardContent style={{paddingBottom: 0}}>
        <Typography variant="h5" component="div">
          Your Schedule for today
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" component="div">
          {`HANI Fresh Milk: ${liters} liter(s)`}
        </Typography>
        <Typography variant="body2">
          <StyledSchedule fontSize='16'/> Delivery Time: ~8:00 PM
        </Typography>
        <Typography variant="body2">
          <StyledDeliveryDining fontSize='16'/> Agent: {agent?.name}
        </Typography>
        { schedule?.note &&
          <Typography variant="body2">
            <StyledInfo fontSize='16'/> Note: {schedule?.note}
          </Typography>
        }
      </CardContent>
      <StyledCardActions >
        { !delivered && liters > 0 &&
          <>
            <Button size="small" onClick={handleModify}
              disabled={isLateToUpdateQuantityForToday()}>Modify</Button>
            <Button size="small" onClick={handleCancel}
              disabled={isLateToUpdateQuantityForToday()}>Cancel</Button>
          </>
        }
        {delivered && liters > 0 &&
          <Button size="small" disabled>Delivered</Button>}
        {liters === 0 &&
          <Button size="small" disabled>Cancelled</Button>}
      </StyledCardActions>
    </StyledCard>);
};

export default TodaySchedule;
