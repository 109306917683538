import { CardContent, Typography } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../../contexts';
import { StyledCard, StyledDeliveryDining,
  StyledCheckCircle} from '../CustomerHome/util';

function TodaySchedule({total, liters, completed}) {
  const user = useContext(UserContext);
  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h5" component="div">
          {
            ( user.currentRole === 'Manager' || user.currentRole === 'Admin' ) ?
            'All Schedule for today' : 'Your Schedule for today'}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" component="div">
          {`HANI Fresh Milk: ${liters} liter(s) / ${liters * 2} bottles`}
        </Typography>
        <Typography variant="body2">
          <StyledDeliveryDining fontSize='16'/>
          {`Pending Deliveries: ${total-completed}`}
        </Typography>
        <Typography variant="body2">
          <StyledCheckCircle fontSize='16'/> {`Delivered: ${completed}`}
        </Typography>
      </CardContent>
    </StyledCard>);
};

export default TodaySchedule;
