import { useEffect, useState } from "react";
import { StyledH4 } from "../../home/CustomerHome/util";
import { IconButton, TextField } from "@mui/material";
import { MyLocationRounded } from "@mui/icons-material";
import styled from '@emotion/styled';
import { getCurrentLocation } from "../../utils/common";

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

function GeoLocation({latLong, onChange}) {
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);

  const handleLat = (event) => setLat(event.target.value);
  const handleLong = (event) => setLong(event.target.value);

  useEffect(() => {
    onChange([lat, long]);
  }, [lat, long]);

  useEffect(() => {
    if (latLong && latLong.length === 2) {
      const [propLat, propLong] = latLong;
      setLat(Number(propLat));
      setLong(Number(propLong));
    }
  }, [latLong]);

  const getLocation = () => {
    getCurrentLocation(({latitude, longitude}) => {
      setLat(latitude);
      setLong(longitude);
    });
  };

  return (<>
    <StyledDiv>
      <StyledH4 text={'Location'}/>
      <IconButton aria-label="Get my current location"
        onClick={getLocation}>
        <MyLocationRounded />
      </IconButton>
    </StyledDiv>
    <TextField required variant="standard" value={lat}
      label="Latitude" onChange={handleLat} type="number"/><br/>
    <TextField required variant="standard" value={long}
      label="Longitude" onChange={handleLong} type="number"/>
  </>);
};

export default GeoLocation;
