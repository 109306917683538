import { FormControl, InputLabel,
  MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";

function ChooseAgent({agent, availableAgents, onChange}) {
  const [newAgent, setNewAgent] = useState('');
  const handleAgent = (event) => {
    setNewAgent(event.target.value);
    onChange(event.target.value);
  };

  useEffect(() => {
    setNewAgent(agent);
  }, [agent]);

  return (<FormControl fullWidth>
    <InputLabel id="status-label-id" style={{left: '-14px'}}>
        Choose Agent</InputLabel>
    <Select required variant="standard"
      labelId="status-label-id"
      value={newAgent} onChange={handleAgent}>
      {availableAgents.map(({name, id}) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>);
};

export default ChooseAgent;
