import { Typography } from "@mui/material";
import CommonModal from "./CommonModal";
import _ from 'lodash';

function ShowProductInterestModal({show, onClose, product}) {
  const actions = [[`Close`, onClose]];
  return (
    <CommonModal show={show} onClose={onClose} actions={actions}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Thanks for showing Interest
      </Typography>
      <Typography variant="subtitle1" color="text.secondary"
        component="div">
        {`Doorstop delivery for ${_.capitalize(product.name)}
          is almost here! Stay tuned.`}
      </Typography>
    </CommonModal>
  );
};

export default ShowProductInterestModal;
