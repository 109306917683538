import { Button, CardContent, Typography } from "@mui/material";
import moment from "moment";
import { useContext, useState } from "react";
import MarkPaymentModal from "../../components/modals/MarkPaymentModal";
import ViewBillModal from "../../components/modals/ViewBillModal";
import { UserContext } from "../../contexts";
import { DeliveriesContainer as Container,
  StyledCard,
  StyledCardActions,
  StyledH4 } from "../../home/CustomerHome/util";
import ShowMoreOrLess from "../../home/ShowMoreOrLess";

function PreviousBillings({bills, clearCustomer}) {
  const [showMarkPayment, setShowMarkPayment] = useState(false);
  const [showBillDetails, setShowBillDetails] = useState(false);
  const [currnetBill, setCurrentBill] = useState();
  const [more, showMore] = useState(false);
  const user = useContext(UserContext);

  const modifyCurrentBill = (bill) => {
    setCurrentBill(bill);
    setShowMarkPayment(true);
  };

  const viewBillDetails = (bill) => {
    setCurrentBill(bill);
    setShowBillDetails(true);
  };

  const clearCurrentBill = () => {
    setCurrentBill();
    setShowMarkPayment();
    setShowBillDetails();
  };

  const getBillings = () => {
    const showCount = more ? bills.length : 1;
    let billCount = 0;
    const billElements = [];

    while (billCount < showCount) {
      const bill = bills[billCount];
      billCount++;
      billElements.push(<StyledCard key={bill.id}>
        <CardContent style={{paddingBottom: 0}}>
          <Typography variant="subtitle1" color="text.secondary"
            component="div">
            { bill.status === 'PAID' ? 'PAID: ' : 'PENDING / VERIFYING: '}
            {`Rs ${bill.payable}`}
          </Typography>
          <Typography>
            From: {
              moment(
                  new Date(bill.fromDate.seconds * 1000)).format('MMM D, YYYY')
            } |
            To: {
              moment(new Date(bill.toDate.seconds * 1000)).format('MMM D, YYYY')
            }
          </Typography>
          <Typography variant="body2">
            HANI Milk: {bill.liters} liter(s)
          </Typography>
        </CardContent>
        <StyledCardActions>
          { user.currentRole === 'Admin' && bill.status !== 'PAID' &&
            <Button size="small" onClick={() => modifyCurrentBill(bill)}>
              Modify</Button>
          }
          <Button size="small" onClick={() => viewBillDetails(bill)}>
            View</Button>
        </StyledCardActions>
      </StyledCard>);
    }

    return billElements;
  };

  return (<Container>
    { bills.length > 0 && (<>
      <StyledH4 text={'Previous Billings'}/>
      <ShowMoreOrLess onToggle={showMore} moreCount={bills.length}/>
      {getBillings()}
    </>) }
    <MarkPaymentModal show={showMarkPayment} bill={currnetBill}
      onClose={clearCurrentBill} onComplete={clearCustomer}/>
    <ViewBillModal show={showBillDetails} bill={currnetBill}
      onClose={clearCurrentBill} />
  </Container>);
};

export default PreviousBillings;
