import React, { useContext, useEffect, useState } from 'react';
import styled from "@emotion/styled";
import { Button, Menu, MenuItem } from "@mui/material";
import { UserContext } from '../contexts';

const StyledDiv = styled.div`
  position: absolute;
  right: 12px;
`;
function UserRole() {
  const user = useContext(UserContext);
  const [role, setRole] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (changedRole) => {
    if (changedRole && user?.roles.includes(changedRole)) {
      setRole(changedRole);
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    setRole(user.currentRole);
  }, [user]);

  useEffect(() => {
    if (role) {
      user?.changeRole(role);
    }
  }, [role]);

  if (!user.roles || user.roles?.length <= 1) {
    return null;
  }

  return (
    <StyledDiv>
      <Button
        id="role-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="text" size="small"
      >
        {role}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {user?.roles.map((r) => (
          <MenuItem onClick={() => handleClose(r)} key={r}>{r}</MenuItem>
        ))}
      </Menu>
    </StyledDiv>
  );
};

export default UserRole;
