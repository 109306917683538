import { Box, Button, Modal } from "@mui/material";
import { isDesktop } from "../../utils/device";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";

const StyledCloseIcon = styled(Close)`
  position: absolute;
  right: 0;
  padding-right: 10px;
  cursor: pointer;
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: isDesktop() ? 400 : '85%',
  backgroundColor: 'white',
  bgcolor: 'background.paper',
  border: 'lightgray 1px solid',
  boxShadow: 24,
  padding: '16px',
  p: 4,
};

const StyledActionsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 16px;
`;

function CommonModal({show, onClose, children, actions}) {
  const onModalClose = () => onClose();
  return (
    <Modal open={!!show} onClose={onModalClose}>
      <Box style={style}>
        <StyledCloseIcon onClick={onModalClose}/>
        {children}
        <StyledActionsDiv>
          {actions.map((action, index) => {
            const [name, onClick, disabled] = action;
            return (
              <Button onClick={onClick} key={name}
                variant={index === 0 ? 'contained' : 'outlined'}
                disabled={disabled}>
                {name}</Button>
            );
          },
          )}
        </StyledActionsDiv>
      </Box>
    </Modal>
  );
};

export default CommonModal;
