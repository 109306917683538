import { FormControl, InputLabel,
  MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";

const availableStatus = ['Subscribed', 'Enquired',
  'Sample Requested', 'Discontinued'];

function CustomerStatus({status, onChange}) {
  const [newStatus, setNewStatus] = useState('');
  const handleStatus = (event) => {
    setNewStatus(event.target.value);
    onChange(event.target.value);
  };

  useEffect(() => {
    setNewStatus(status);
  }, [status]);

  return (<FormControl fullWidth>
    <InputLabel id="status-label-id" style={{left: '-14px'}}>Status</InputLabel>
    <Select required variant="standard"
      labelId="status-label-id"
      value={newStatus} onChange={handleStatus}>
      {availableStatus.map((availableStatus) => (
        <MenuItem key={availableStatus} value={availableStatus}>
          {availableStatus}
        </MenuItem>
      ))}
    </Select>
  </FormControl>);
};

export default CustomerStatus;
